import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BalanceDKI from "../../models/BalanceDKI";
import { saveState, loadState, removeState } from "../browser-storage/browser-storage";

type InitialState = {
    balanceDKI:  BalanceDKI
}
const type = 'balanceDKI'

const initialState: InitialState = {
    balanceDKI: loadState(type)
}

export const balanceDKISlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setBalanceDKI: (state, action: PayloadAction<BalanceDKI>) => {
      state.balanceDKI = action.payload;
      saveState(type, action.payload)
    },
    removeBalanceDKI: (state) => {
      state.balanceDKI = {};
      removeState(type)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBalanceDKI, removeBalanceDKI } =  balanceDKISlice.actions;

export default  balanceDKISlice.reducer;
