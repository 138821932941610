import React, { FC, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector, useAppDispatch } from "../core/feature/hooks";
import Config from "../config/Config";
import { StatefulPinInput } from "react-input-pin-code";
import { ConfigColor } from "../config/global";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface InputPinProps {
  input: any;
  setInput: any;
  show: any;
  setShow: any;
  submit: any;
  width: number;
  height: number;
  margin: string;
  header: any;
  message?: any;
  max?:any;
  setMax?:any
}

const InputPin: FC<InputPinProps> = (props): JSX.Element => {
  const {t}= useTranslation()
  const color = ConfigColor();
  const url = Config.BaseUrlRedirect;
  const token: String = useAppSelector((state) => state.token.token);
  const company: any = useAppSelector((state) => state.company.company);
  const paymentType: any = useAppSelector(
    (state) => state.paymentType.paymentType
  );
  const [data, setData] = useState<any>({});
  const navigateTo = () => {
    window.location.href = `${url}/${company.company.initial}/profile/security-code/forget?link=${company.link}&companyinitial=${company.company.initial}&token=${token}`;
  };
  const check = props.input.filter(
    (el: any) => el === "" || el === "undefined"
  );

  useEffect(() => {}, [props, check]);
  return (
    <div
      className="relative z-10"
      hidden={props.show}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="animate-top flex flex-col items-center justify-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:w-[65%] lg:w-[50%] w-full sm:my-8 md:px-[2.5rem] md:py-[2.5rem] py-[1.5rem] px-[1rem]">
            <div className="flex flex-row justify-end w-100 mb-3 md:px-[0px] px-[0.5rem]">
              <button
                onClick={() => {
                  props.setShow(!props.show);
                }}
                className="fa-xl text-gray-400"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <h6 className="md:text-2xl text-sm text-black md:mb-4">
              {props.header}
            </h6>
            {props.message}
            <div className="flex flex-col w-full items-center">
              <label htmlFor="inputPin">
                <div className="flex flex-row gap-3 relative z-10">
                  {props?.input?.map((res: any, i: any) => {
                    return (
                      <div
                        className={
                          props?.input[i] === "undefined" ||
                          props?.input[i] === ""
                            ? "border outline-none text-center rounded-full w-8 h-8"
                            : "bg-[#26A69A] text-[#26A69A] border outline-none text-center rounded-full w-8 h-8"
                        }
                        key={i}
                      ></div>
                    );
                  })}
                </div>
              </label>
              <div className="w-full -mt-9 relative z-20">
                <input
                  className="w-full bg-transparent border-none indent-[-999em] caret-transparent text-transparent focus:outline-none py-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  type="number"
                  id="inputPin"
                  value={props.max}
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      props.setMax(props.max);
                    } else {
                      const value: any = e.target.value.split("");
                      const newValue: any = props.input;
                      const x: any = [
                        `${(newValue[0] = value[0])}`,
                        `${(newValue[1] = value[1])}`,
                        `${(newValue[2] = value[2])}`,
                        `${(newValue[3] = value[3])}`,
                        `${(newValue[4] = value[4])}`,
                        `${(newValue[5] = value[5])}`,
                      ];
                      props.setInput(x);
                      props.setMax(e.target.value);
                    }
                  }}
                ></input>
              </div>
            </div>

            <button
              disabled={check.length > 0}
              onClick={props.submit}
              className={`${
                check.length > 0 ? "bg-gray-300" : "block"
              } w-full md:py-[16px] p-[10px] rounded-full text-white font-semibold text-base disabled:bg-gray-300 mt-4`}
              style={{
                backgroundColor: check.length > 0 ? "#9CA3AF" : color.color1,
              }}
            >
              {t("balance.pinButton")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputPin;
