const respEn = {
  instructions_va: [
    {
      id: "mBanking",
      title: "Instructions for Transfer via M banking",
      instruction: [
        {
          id: "1",
          idlevel1: "mBanking",
          title: "Open and log in to the M-banking application",
        },
        {
          id: "2",
          idlevel1: "mBanking",
          title: "Select Transfer or Fund Transfer menu",
        },
        { id: "3", idlevel1: "mBanking", title: "Select source account" },
        {
          id: "4",
          idlevel1: "mBanking",
          title: "Select Transfer to Virtual Account option for transfer destination",
        },
        {
          id: "5",
          idlevel1: "mBanking",
          title: "Enter virtual account number {get_VA}",
        },
        {
          id: "6",
          idlevel1: "mBanking",
          title:
            "The application will display information about the transfer, including the destination VA number and the amount to be transferred. Make sure this information is correct before proceeding.",
        },
        {
          id: "7",
          idlevel1: "mBanking",
          title: "Confirm the transaction.",
        },
      ],
    },
    {
      id: "ATM",
      title: "Instructions for Transfer via ATM",
      instruction: [
        {
          id: "1",
          idlevel1: "ATM",
          title: "Insert ATM card into the ATM machine",
        },
        {
          id: "2",
          idlevel1: "ATM",
          title: "Select Language",
        },
        { id: "3", idlevel1: "ATM", title: "Select source account" },
        {
          id: "4",
          idlevel1: "ATM",
          title: `Select "To BRI Account" option for transfer type`,
        },
        {
          id: "5",
          idlevel1: "ATM",
          title: "Enter virtual account number {get_VA}",
        },
        {
          id: "6",
          idlevel1: "ATM",
          title:
            "The ATM machine will display payment information, including the Fixed Payment VA number and the amount to be paid. Make sure this information is correct before proceeding.",
        },
        {
          id: "7",
          idlevel1: "ATM",
          title: "Confirm the transaction.",
        },
      ],
    },
    {
      id: "internet",
      title: "Instructions for Transfer via I Banking",
      instruction: [
        {
          id: "1",
          idlevel1: "internet",
          title: "Access and log in to the internet banking application",
        },
        {
          id: "2",
          idlevel1: "internet",
          title: "Select Transfer or Transaction menu",
        },
        { id: "3", idlevel1: "internet", title: "Select source account" },
        {
          id: "4",
          idlevel1: "internet",
          title: `Select transfer destination to virtual account`,
        },
        {
          id: "5",
          idlevel1: "internet",
          title: "Enter virtual account number {get_VA}",
        },
        {
          id: "6",
          idlevel1: "internet",
          title:
            "The Internet Banking platform will display information about the transfer, including the destination VA number and the amount to be transferred. Make sure all information is correct before proceeding.",
        },
        {
          id: "7",
          idlevel1: "internet",
          title: "Confirm the transaction.",
        },
      ],
    },
  ],
  "instructions_va_BSI": [
    {
      "id": "mBanking",
      "title": "Instructions for Transferring via M banking",
      "instruction": [
        {
          "id": "1",
          "idlevel1": "mBanking",
          "title": "Login to your BSI Mobile Banking application"
        },
        {
          "id": "2",
          "idlevel1": "mBanking",
          "title": "Select the Pay menu"
        },
        {
          "id": "3",
          "idlevel1": "mBanking",
          "title": "Select Institution"
        },
        {
          "id": "4",
          "idlevel1": "mBanking",
          "title": "Enter your institution code by typing the first 4 digits of your Virtual Account, which are {institution_code}"
        },
        {
          "id": "5",
          "idlevel1": "mBanking",
          "title": "Enter your payment code in the form of your Virtual Account by removing the first 4 digits of your Virtual Account, which are {get_VA_BSI}"
        },
        {
          "id": "6",
          "idlevel1": "mBanking",
          "title": "Click next"
        },
        {
          "id": "7",
          "idlevel1": "mBanking",
          "title": "Enter the transfer amount"
        },
        {
          "id": "8",
          "idlevel1": "mBanking",
          "title": "Enter PIN"
        },
        {
          "id": "9",
          "idlevel1": "mBanking",
          "title": "Confirm payment"
        }
      ]
    },
    {
      "id": "ATM",
      "title": "Instructions for Transferring via ATM",
      "instruction": [
        {
          "id": "1",
          "idlevel1": "ATM",
          "title": "Insert your ATM card into the ATM machine"
        },
        {
          "id": "2",
          "idlevel1": "ATM",
          "title": "Select Language"
        },
        {
          "id": "3",
          "idlevel1": "ATM",
          "title": "Select source account"
        },
        {
          "id": "4",
          "idlevel1": "ATM",
          "title": `Select the option "To BRI Account" on the transfer type selection`
        },
        {
          "id": "5",
          "idlevel1": "ATM",
          "title": "Enter the virtual account number {get_VA}"
        },
        {
          "id": "6",
          "idlevel1": "ATM",
          "title": "The ATM machine will display information about the payment, including the Fixed Payment VA number and the amount to be paid. Make sure this information is correct before proceeding."
        },
        {
          "id": "7",
          "idlevel1": "ATM",
          "title": "Confirm transaction"
        }
      ]
    },
    {
      "id": "internet",
      "title": "Instructions for Transferring via I Banking",
      "instruction": [
        {
          "id": "1",
          "idlevel1": "internet",
          "title": "Access and log in to the internet banking application"
        },
        {
          "id": "2",
          "idlevel1": "internet",
          "title": "Select the Transfer or Transaction menu"
        },
        {
          "id": "3",
          "idlevel1": "internet",
          "title": "Select source account"
        },
        {
          "id": "4",
          "idlevel1": "internet",
          "title": `Select destination transfer to virtual account`
        },
        {
          "id": "5",
          "idlevel1": "internet",
          "title": "Enter the virtual account number {get_VA}"
        },
        {
          "id": "6",
          "idlevel1": "internet",
          "title": "The Internet Banking platform will display information about the transfer, including the destination VA number and the amount to be transferred. Make sure all information is correct before proceeding."
        },
        {
          "id": "7",
          "idlevel1": "internet",
          "title": "Confirm transaction"
        }
      ]
    }
  ],
}

export default respEn