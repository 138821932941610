import { configureStore } from "@reduxjs/toolkit";
import companyReducer from "./company/companySlice";
import userReducer from "./user/userSlice";
import balanceReducer from "./user/balanceSlice";
import configReducer from "./config/configSlice";
import tokenReducer from "./token/tokenSlice";
import paymentTypeReducer from "./PaymentType/PaymentType"
import balanceCreditReducer from "./balanceCredit/balanceCreditSlice";
import balanceCodeReducer from "./BalanceCode/BalanceCode"
import balanceLinkAjaReducer from "./BalanceLinkAja/BalanceLinkAja"
import balanceDKIReducer from "./BalanceDKI/BalanceDKI";

// export default configureStore({
//   reducer: {
//     company: companyReducer,
//   },
// });
const initStore = (preloadedState = {}) => {
  return configureStore({
    reducer: companyReducer,
    preloadedState,
  });
}

export const store = configureStore({
  reducer: {
    company: companyReducer,
    user: userReducer,
    balance: balanceReducer,
    config: configReducer,
    token: tokenReducer,
    paymentType : paymentTypeReducer,
    balanceCode : balanceCodeReducer,
    balanceCredit: balanceCreditReducer,
    balanceLinkAja : balanceLinkAjaReducer,
    balanceDKI : balanceDKIReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store;