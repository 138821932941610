import { useEffect, useState } from "react";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QrisLabel from "../assets/images/icon_checkout/qris-label.svg";
import Navbar from "../components/Navbar";
import { useAppDispatch, useAppSelector } from "../core/feature/hooks";

import moment from "moment";
import Down from "../assets/images/icon_checkout/Down.svg";
import Up from "../assets/images/icon_checkout/Up.png";
import left from "../assets/images/icon_checkout/arrow-left.svg";
import right from "../assets/images/icon_checkout/arrow-right.svg";

import { useNavigate, useParams } from "react-router-dom";

import { formatCurrency } from "../config/global";
import { setPaymentTypeRedux } from "../core/feature/PaymentType/PaymentType";
import { setLoading } from "../core/feature/config/configSlice";
import User from "../core/models/user";

import Footer from "../components/Footer";
import * as API_PAYMENT from "../core/service/api_payment.js";

import CancelComponent from "../components/CancelComponent";
import Description from "../components/Description";

import { ConfigColor, hexToRgba } from "../config/global";
import { useTranslation } from "react-i18next";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const PaymentQris = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const color = ConfigColor();
  const user: User = useAppSelector((state) => state.user.user);
  const token: String = useAppSelector((state) => state.token.token);
  const company: any = useAppSelector((state) => state.company.company);
  const paymentType: any = useAppSelector(
    (state) => state.paymentType.paymentType
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [QrString, setQrString] = useState("");
  const [amount, setAmount] = useState("");
  const [hide, setHide] = useState(true);
  const [BniHideOne, setBniHideOne] = useState(true);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(true);
  const [dateNow, setDateNow] = useState<any>();
  const customSuccesUrl = JSON.parse(
    localStorage.getItem("customSuccessUrl") as any
  );
  const customBackUrl = JSON.parse(
    localStorage.getItem("customBackUrl") as any
  );

  const title = "Pembayaran";
  document.title = title;

  const actionHide = () => {
    if (hide) {
      setHide(false);
    } else {
      setHide(true);
    }
  };

  const actionBniOne = () => {
    if (BniHideOne) {
      setBniHideOne(false);
    } else {
      setBniHideOne(true);
    }
  };

  const cancel = () => {
    setAlert(false);
    setMessage(t("modalCancel.about"));
  };

  const actionCancel = () => {
    dispatch(setPaymentTypeRedux({}));
    localStorage.setItem("choose_payment", false as any);
    if (token) {
      if (customSuccesUrl && customBackUrl) {
        navigate(
          `/${params.id}?access_token=${token}&successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
        );
        dispatch(setPaymentTypeRedux({}));
      } else {
        navigate(`/${params.id}?access_token=${token}`);
        dispatch(setPaymentTypeRedux({}));
      }
    } else {
      if (customSuccesUrl && customBackUrl) {
        navigate(
          `/${params.id}?successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
        );
        dispatch(setPaymentTypeRedux({}));
      } else {
        navigate(`/${params.id}`);
        dispatch(setPaymentTypeRedux({}));
      }
    }
  };

  const instructionPayment = (el: any) => {
    if (i18n.language === "en") {
      switch (el) {
        case "XENDIT":
          return (
            <div className="space-y-4">
              <div className="">
                <span>Payment Instructions</span>
              </div>
              <div className="">
                <div className="border border-slate-200 rounded-xl bg-white">
                  <div
                    className="grid grid-cols-4 gap-4 hover:text-emerald-400 cursor-pointer"
                    onClick={actionBniOne}
                  >
                    <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[50px] lg:h-[50px] xl:h-[50px] 2xl:h-[60px] px-4">
                      <div className="text-xs md:text-lg">
                        Instructions for Transfer via Qris
                      </div>
                    </div>
                    <div className="flex justify-end items-center pr-4 cursor-pointer">
                      <img src={BniHideOne ? Down : Up} alt="" />
                    </div>
                  </div>
                  <div
                    className="bg-[#F2F2F2] p-2 text-slate-400 text-xs space-y-2"
                    hidden={BniHideOne}
                  >
                    <div className="flex items-center space-x-4">
                      <div className="md:text-lg border-dashed border-b border-slate-400 py-2 w-full">
                        <span>
                          <b>Instructions</b>
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full text-[#FFFFFF] p-2 md:text-lg w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        1
                      </div>
                      <div className="md:text-lg border-b border-slate-400 py-2 w-full">
                        Log in to the Mobile Banking or E-wallet Account of Your
                        Choice
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full text-[#FFFFFF] p-2 md:text-lg w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        2
                      </div>
                      <div className="md:text-lg border-b border-slate-400 py-2 w-full">
                        <span>Select Payment Method by Scanning QR</span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full text-[#FFFFFF] p-2 md:text-lg w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        3
                      </div>
                      <div className="md:text-lg border-b border-slate-400 py-2 w-full">
                        <span>
                          Point the Camera at this Qris, or You Can Take a
                          Screenshot of this Qris and Then Upload It
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "BANK_DKI":
          return (
            <div className="space-y-4">
              <div className="">
                <span>Payment Instructions</span>
              </div>
              <div className="">
                <div className="border border-slate-200 rounded-xl bg-white">
                  <div
                    className="grid grid-cols-4 gap-4 hover:text-emerald-400 cursor-pointer"
                    onClick={actionBniOne}
                  >
                    <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[50px] lg:h-[50px] xl:h-[50px] 2xl:h-[60px] px-4">
                      {/* <img
                        src={`${iconPayment(
                          paymentType.selectedPaymentMethod.provider
                        )}`}
                        className="md:w-10 md:h-10 2xl:w-12 2xl:h-12"
                      /> */}
                      <div className="text-xs md:text-lg">
                        Instructions for Transfer via Qris
                      </div>
                    </div>
                    <div className="flex justify-end items-center pr-4 cursor-pointer">
                      <img src={BniHideOne ? Down : Up} alt="" />
                    </div>
                  </div>
                  <div
                    className="bg-[#F2F2F2] p-2 text-slate-400 text-xs space-y-2"
                    hidden={BniHideOne}
                  >
                    <div className="flex items-center space-x-4">
                      <div className="md:text-lg border-dashed border-b border-slate-400 py-2 w-full">
                        <span>
                          <b>Instructions</b>
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full text-[#FFFFFF] p-2 md:text-lg w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        1
                      </div>
                      <div className="md:text-lg border-b border-slate-400 py-2 w-full">
                        Log in to the Mobile Banking or E-wallet Account of Your
                        Choice
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full text-[#FFFFFF] p-2 md:text-lg w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        2
                      </div>
                      <div className="md:text-lg border-b border-slate-400 py-2 w-full">
                        <span>Select Payment Method by Scanning QR</span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full text-[#FFFFFF] p-2 md:text-lg w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        3
                      </div>
                      <div className="md:text-lg border-b border-slate-400 py-2 w-full">
                        <span>
                          Point the Camera at this Qris, or You Can Take a
                          Screenshot of this Qris and Then Upload It
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        default:
          return (
            <div className="space-y-4">
              <div className="">
                <span>Payment Instructions</span>
              </div>
              <div className="">
                <div className="border border-slate-200 rounded-xl bg-white">
                  <div
                    className="grid grid-cols-4 gap-4 hover:text-emerald-400 cursor-pointer"
                    onClick={actionBniOne}
                  >
                    <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[50px] lg:h-[50px] xl:h-[50px] 2xl:h-[60px] px-4">
                      <div className="text-xs md:text-lg">
                        Instructions for Transfer via Qris
                      </div>
                    </div>
                    <div className="flex justify-end items-center pr-4 cursor-pointer">
                      <img src={BniHideOne ? Down : Up} alt="" />
                    </div>
                  </div>
                  <div
                    className="bg-[#F2F2F2] p-2 text-slate-400 text-xs space-y-2"
                    hidden={BniHideOne}
                  >
                    <div className="flex items-center space-x-4">
                      <div className="md:text-lg border-dashed border-b border-slate-400 py-2 w-full">
                        <span>
                          <b>Instructions</b>
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full text-[#FFFFFF] p-2 md:text-lg w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        1
                      </div>
                      <div className="md:text-lg border-b border-slate-400 py-2 w-full">
                        Log in to the Mobile Banking or E-wallet Account of Your
                        Choice
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full text-[#FFFFFF] p-2 md:text-lg w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        2
                      </div>
                      <div className="md:text-lg border-b border-slate-400 py-2 w-full">
                        <span>
                          If Available, Select the Payment Method by Scanning QR
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full text-[#FFFFFF] p-2 md:text-lg w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        3
                      </div>
                      <div className="md:text-lg border-b border-slate-400 py-2 w-full">
                        <span>Point the Camera at the Provided Barcode</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
      }
    } else {
      switch (el) {
        case "XENDIT":
          return (
            <div className="space-y-4">
              <div className="">
                <span>Petunjuk Pembayaran</span>
              </div>
              <div className="">
                <div className="border border-slate-200 rounded-xl bg-white">
                  <div
                    className="grid grid-cols-4 gap-4  hover:text-emerald-400 cursor-pointer"
                    onClick={actionBniOne}
                  >
                    <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[50px] lg:h-[50px] xl:h-[50px] 2xl:h-[60px] px-4">
                      <div className="text-xs md:text-lg  ">
                        Petunjuk Transfer Melalui Qris
                      </div>
                    </div>
                    <div className="flex justify-end items-center pr-4 cursor-pointer">
                      <img src={BniHideOne ? Down : Up} alt="" />
                    </div>
                  </div>
                  <div
                    className="bg-[#F2F2F2] p-2 text-slate-400 text-xs space-y-2"
                    hidden={BniHideOne}
                  >
                    <div className="flex items-center space-x-4">
                      <div className="md:text-lg  border-dashed border-b border-slate-400 py-2 w-full">
                        <span>
                          <b>Intruksi</b>
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF]  p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        1
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        Masuk ke Akun Mobile Banking atau E-wallet yang Anda
                        Pilih
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF]  p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        2
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        <span>Pilih Metode Pembayaran dengan Scan Qr</span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF]   p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        3
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        <span>
                          Arahkan Kamera ke Qris ini, atau Anda bisa Mengambil
                          Screensot dari Qris ini Kemudian Upload
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        case "BANK_DKI":
          return (
            <div className="space-y-4">
              <div className="">
                <span>Petunjuk Pembayaran</span>
              </div>
              <div className="">
                <div className="border border-slate-200 rounded-xl bg-white">
                  <div
                    className="grid grid-cols-4 gap-4  hover:text-emerald-400 cursor-pointer"
                    onClick={actionBniOne}
                  >
                    <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[50px] lg:h-[50px] xl:h-[50px] 2xl:h-[60px] px-4">
                      {/* <img
                        src={`${iconPayment(
                          paymentType.selectedPaymentMethod.provider
                        )}`}
                        className="md:w-10 md:h-10 2xl:w-12 2xl:h-12"
                      /> */}
                      <div className="text-xs md:text-lg  ">
                        Petunjuk Transfer Melalui Qris
                      </div>
                    </div>
                    <div className="flex justify-end items-center pr-4 cursor-pointer">
                      <img src={BniHideOne ? Down : Up} alt="" />
                    </div>
                  </div>
                  <div
                    className="bg-[#F2F2F2] p-2 text-slate-400 text-xs space-y-2"
                    hidden={BniHideOne}
                  >
                    <div className="flex items-center space-x-4">
                      <div className="md:text-lg  border-dashed border-b border-slate-400 py-2 w-full">
                        <span>
                          <b>Intruksi</b>
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF]  p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        1
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        Masuk ke Akun Mobile Banking atau E-wallet yang Anda
                        Pilih
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF]  p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        2
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        <span>Pilih Metode Pembayaran dengan Scan Qr</span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF] p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        3
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        <span>
                          Arahkan Kamera ke Qris ini, atau Anda bisa Mengambil
                          Screensot dari Qris ini Kemudian Upload
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );

        default:
          return (
            <div className="space-y-4">
              <div className="">
                <span>Petunjuk Pembayaran</span>
              </div>
              <div className="">
                <div className="border border-slate-200 rounded-xl bg-white">
                  <div
                    className="grid grid-cols-4 gap-4  hover:text-emerald-400 cursor-pointer"
                    onClick={actionBniOne}
                  >
                    <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[50px] lg:h-[50px] xl:h-[50px] 2xl:h-[60px] px-4">
                      <div className="text-xs md:text-lg  ">
                        Petunjuk Transfer Melalui Qris
                      </div>
                    </div>
                    <div className="flex justify-end items-center pr-4 cursor-pointer">
                      <img src={BniHideOne ? Down : Up} alt="" />
                    </div>
                  </div>
                  <div
                    className="bg-[#F2F2F2] p-2 text-slate-400 text-xs space-y-2"
                    hidden={BniHideOne}
                  >
                    <div className="flex items-center space-x-4">
                      <div className="md:text-lg  border-dashed border-b border-slate-400 py-2 w-full">
                        <span>
                          <b>Intruksi</b>
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF]  p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        1
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        Masuk ke Akun Mobile Banking atau E-wallet yang Anda
                        Pilih
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF]  p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        2
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        <span>
                          Jika Tersedia Pilih Metode Pembayaran dengan Cara Scan
                          Qr
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF]   p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{ backgroundColor: color.color1 }}
                      >
                        3
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        <span>
                          Arahkan Kamera ke Barcode yang Telah Tersedia
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
      }
    }
  };

  const getCheckout = async (showLoader = true) => {
    dispatch(setLoading(showLoader));
    await API_PAYMENT.getCheckout(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        setDateNow(Date.now());
        if (res.data.data.status !== "OPEN") {
          if (customSuccesUrl && customBackUrl) {
            navigate(
              `/${params.id}?successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
            );
            dispatch(setPaymentTypeRedux({}));
          } else {
            navigate(`/${params.id}`);
            dispatch(setPaymentTypeRedux({}));
          }
        } else if (res.data.data.selectedPaymentMethod == null || undefined) {
          if (customSuccesUrl && customBackUrl) {
            navigate(
              `/${params.id}?successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
            );
            dispatch(setPaymentTypeRedux({}));
          } else {
            navigate(`/${params.id}`);
            dispatch(setPaymentTypeRedux({}));
          }
        } else {
          dispatch(setPaymentTypeRedux(res.data.data));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (paymentType?.status == "OPEN") {
      const loop = setInterval(() => {
        getCheckout(false);
      }, 8000);
      return () => clearInterval(loop);
    }
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  useEffect(() => {
    var date = moment.utc(paymentType?.expiredAt).format("YYYY-MM-DD HH:mm:ss");

    var stillUtc = moment.utc(date).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");

    var timeArr = moment(local).format("x");
    let expired = parseInt(timeArr);
    if (dateNow >= expired) {
      if (token) {
        navigate(`/${params.id}?access_token=${token}`);
        dispatch(setPaymentTypeRedux({}));
      } else {
        navigate(`/${params.id}`);
        dispatch(setPaymentTypeRedux({}));
      }
    }
  }, [dateNow]);

  return (
    <>
      <CancelComponent
        show={alert}
        setShow={setAlert}
        message={message}
        submit={actionCancel}
      />
      <Navbar link={cancel} />
      <div className="p-4 md:w-[50%] 2xl:w-[40%] h-full mx-auto text-sm md:text-lg">
        <div className="animate-top space-y-12">
          <Description />
          <div className="border border-slate-400 rounded-xl flex flex-col bg-white">
            <div className="flex items-center space-x-2 p-4 border-b border-slate-200 ">
              <div>{t("qris.title")}</div>
            </div>
          </div>
          <div className="space-y-5">
            <div className="border border-slate-400 rounded-xl mx-auto w-10/12 md:w-2/4 lg:w-3/4 xl:w-[55%] 2xl:w-[35%] py-4 bg-white">
              <div>
                <img src={left} alt="" />
              </div>
              <div className="flex justify-center">
                <img
                  src={paymentType?.requiredPaymentData?.qris}
                  className=""
                />
              </div>
              <div className="flex justify-end">
                <img src={right} alt="" />
              </div>
              <div>
                <img src={QrisLabel} alt="" className="mx-auto" />
              </div>
            </div>
            <div
              className="bg-opacity-20 rounded w-full md:w-10/12 2xl:w-2/4 mx-auto py-3 space-y-2"
              style={{
                color: color.color1,
                backgroundColor: hexToRgba(color.color1, 0.1),
              }}
            >
              <div className=" text-center text-black">
                {t("qris.nominalPay")}
              </div>
              <div className="md:text-lg  text-center font-bold">
                {formatCurrency(paymentType?.amount)}
              </div>
            </div>
          </div>
          {instructionPayment(paymentType?.selectedPaymentMethod?.provider)}

          <div className=" w-full mt-14 space-y-4 py-2 pb-4  mx-auto">
            <div className="flex justify-center">
              <button
                className="bg-[#FFFFFF] w-full h-[40px] rounded-lg border-1 border-[#9A9999] text-[#9A9999] active:bg-slate-200 active:text-[#FFFFFF]"
                onClick={cancel}
              >
                {t("global.buttonCancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentQris;
