import Config from "../../config/Config";
import ErrorHandler from "./errorHandler";
import { setupInterceptorsTo } from "./Interceptors";
import axios from "axios";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
setupInterceptorsTo(axios);

const baseUrlDev = Config.BaseUrl;

export const checkCompany = (initial) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}mob_user/check_company?companyInitial=${initial}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        ErrorHandler(err);
        reject(err);
      });
  });
};

export const login = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}mob_merchant/login`,
        new URLSearchParams(data).toString(),
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const confirmasi_email = (query) => {
  let url = `${baseUrlDev}mob_user/confirm_email?email=${query.email}`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${url}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_co_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const confirmasi_otp = (query) => {
  let url = `${baseUrlDev}mob_user/validation_email?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${url}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_co_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const confirmation_otp_login = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}mob_user/otp_login`, data, {
        headers: {},
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cekToken = (userType) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}authentication/${userType}/token`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_co_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}api_default/auth/logout`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const forgotPassword = (data) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(
//         `${baseUrlDev}api_default/auth/get_otp/user_membership_merchant?email=${data.email}&companyId=${data.companyId}`
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const forgotPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}api_default/auth/get_otp/user_membership_merchant?email=${data.email}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const inputOtp = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}api_default/auth/forgot_password/user_membership_merchant/${data.otp}?newPassword=${data.password}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createPassword = (password) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}api_default/auth/user_change_password_first_login/user_membership_merchant`,
        password,
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_co_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createSecurityCode = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrlDev}mob_user/security_code`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_co_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getConfigSelfRegister = (companyId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}mob_user/get_config_self_register/${companyId}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resend_otp = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}mob_user/get_otp/user_membership_member?email=${data.email}&companyId=${data.companyId}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const requestOtpSelfRegister = (query) => {
  let url = `${baseUrlDev}mob_user/request_otp_self_register?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`
  return new Promise((resolve, reject) => {
    axios
      .post(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const selfRegister = (query, data) => {
  let url = `${baseUrlDev}mob_user/send_self_registration_data?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`
  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};