import Config from "../../config/Config";
const axios = require("axios");

const baseUrlDev = Config.BaseUrl;

export const getBalance = (company,userType) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/${userType}/balance/${company}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_co_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getBalanceLinkAJa = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/member/linked_account/link_aja/check_account`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_co_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getBalanceDKI = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/member/linked_account/bank_dki/check_account`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_co_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
