import { FC, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ConfigColor } from "../config/global.js";
import { useAppDispatch, useAppSelector } from "../core/feature/hooks";
import Company from "../core/models/company";
import { useTranslation } from "react-i18next";

interface InputPinProps {
  show: any;
  setShow: any;
  submit: any;
  message?: any;
}

const CancelComponent: FC<InputPinProps> = (props): JSX.Element => {
  const color = ConfigColor();
  const { t } = useTranslation();

  return (
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none backdrop-blur-sm focus:outline-none"
      hidden={props.show}
    >
      <div className="animate-top relative mx-auto flex justify-center text-sm md:text-lg">
        {/*content*/}
        <div className="border-0 shadow-lg relative flex flex-col bg-[#FFFFFF] outline-none focus:outline-none rounded-xl w-4/5 md:w-[500px] 2xl:w-[600px]">
          {/*body*/}
          <div className="relative space-y-4 px-4">
            <div
              className="text-center text-lg md:text-2xl pt-4"
              style={{ color: color.color1 }}
            >
              {t("modalCancel.title")}
            </div>
            <div className="text-center">{props.message}</div>
          </div>
          <div className="grid grid-cols-2 rounded-b px-4 py-4 space-x-2">
            <div>
              <button
                className="border-1 border-[#728F9E] w-full px-6 mx-auto rounded-lg text-[#728F9E] active:bg-[#728F9E] active:text-white font-bold uppercase text-sm py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => props.setShow(!props.show)}
              >
                {t("modalCancel.buttonCancel")}
              </button>
            </div>
            <div>
              <button
                style={{
                  backgroundColor: color.color1,
                }}
                className="w-full px-6 mx-auto rounded-lg text-white active:bg-emerald-600 font-bold uppercase text-sm py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={props.submit}
              >
                {t("modalCancel.buttonConfir")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelComponent;
