import React, { Component, useEffect, useState } from "react";
import {} from "../core/feature/store";
import { useAppSelector, useAppDispatch } from "../core/feature/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import paid from "../assets/images/icon_checkout/paid.svg";
import _ from "lodash";
import { ConfigColor } from "../config/global";
import Countdown, { zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";
interface EmployeeProps {
  show: any;
}
const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

export default function Paid({ show }: EmployeeProps) {
  const { t } = useTranslation();
  const color = ConfigColor();
  const company: any = useAppSelector((state) => state.company.company);
  const [countdown, setCountdown] = useState(false);
  const [timer, setTimer] = useState<any>(0);
  const customSuccesUrl = JSON.parse(
    localStorage.getItem("customSuccessUrl") as any
  );
  const customBackUrl = JSON.parse(
    localStorage.getItem("customBackUrl") as any
  );

  // Karakter yang ingin dicari dalam nilai key
  const charToFind = "https://";

  // Mendapatkan nilai dari key menggunakan _.get
  const value = _.get(company, "successRedirectUrl");

  // Memeriksa apakah karakter tertentu ada dalam nilai key menggunakan _.includes

  const title = "Pembayaran";
  document.title = title;

  const goToBack = () => {
    if (customSuccesUrl) {
      window.location.href = `https://${customSuccesUrl}`;
    } else {
      if (_.includes(value, charToFind)) {
        window.location.href = `${company?.successRedirectUrl}`;
      } else {
        window.location.href = `https://${company?.successRedirectUrl}`;
      }
    }
    localStorage.removeItem("customSuccessUrl");
    localStorage.removeItem("customBackUrl");
    localStorage.removeItem("customBaseUrl");
  };
  const renderer = (
    // { hours, minutes, seconds, completed }
    data: any
  ) => {
    if (data.completed) {
      if (customSuccesUrl) {
        window.location.href = `https://${customSuccesUrl}`;
      } else {
        window.location.href = `${company?.successRedirectUrl}`;
      }
      localStorage.removeItem("customSuccessUrl");
      localStorage.removeItem("customBackUrl");
      localStorage.removeItem("customBaseUrl");
    } else {
      setCountdown(true);
      // Render a countdown
      let countdown = {
        days: zeroPad(data.days),
        hours: zeroPad(data.hours),
        minutes: zeroPad(data.minutes),
        seconds: zeroPad(data.seconds),
      };
      return (
        <span className="text-sm md:text-lg 2xl:text-xl text-center font-bold">
          {countdown.seconds == "00" ? "" : countdown.seconds + " detik"}
        </span>
      );
    }
  };
  useEffect(() => {
    if (show == false) {
      setTimer(Date.now() + 10000);
    }
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  return (
    <>
      <div className="w-full md:w-3/4 2xl:2/5 h-full mx-auto">
        <div className="space-y-4">
          <div className="mt-16 text-center ">
            <img
              src={paid}
              alt=""
              className="mx-auto mb-4 w-[100%] md:w-[50%] lg:w-[50%] xl:w-[40%] 2xl:w-[40%]"
            />
            <span className="text-slate-400 text-xl md:text-2xl 2xl:text-2xl font-semibold">
              {t("paid.message")}
            </span>
          </div>
          <div className="space-y-16">
            <div className="flex justify-center">
              <Countdown key={timer} date={timer} renderer={renderer} />
            </div>
            {company?.userType?.toLowerCase() === "member" && (
              <div className="flex justify-center px-4">
                <button
                id="success-button"
                  className="w-full text-white p-2 rounded md:w-[30%]"
                  onClick={goToBack}
                  style={{ backgroundColor: color.color1 }}
                >
                  {t("paid.buttonBack")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
