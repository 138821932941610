import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import Company from "../models/company";
import { toast } from "react-hot-toast";
import ToastConfig from "../../config/Toast";
import "react-toastify/dist/ReactToastify.css";
import { store } from "../../core/feature/store";
import { removeToken } from "../../core/feature/token/tokenSlice";
import { ValidateError } from "./interceptor_error_message";

const reduxState = store.getState();

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.log(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  console.log(error);
  if (localStorage.getItem("company_co_redux")) {
    const company: any = JSON.parse(
      localStorage.getItem("company_co_redux") as string
    );
    let message: any = "";
    let data = error.response?.data as any;
    if (error.response && error.response.data) {
      // console.log(error.response)
      console.log(data);

      message = await ValidateError(data);
      if (data.errorCodes) message = await ValidateError(data.errorCodes[0]);
    }

    if (error.response?.status == 401 && error.config?.url?.includes("login")) {
      message = `${message}. Please Login First!`;
    }
    if ([500, 501, 502, 503, 0].includes(error.response?.status as number)) {
      message = "Terjadi Kesalahan Sistem";
    }
    if (
      [403].includes(error.response?.status as number) &&
      !error.config?.url?.includes("mob_user/security_code/reauthenticate")
    ) {
      message = "Masukkan PIN anda";
    }

    if (
      [401].includes(error.response?.status as number) &&
      error.config?.url?.includes("mob_user/login")
    ) {
      message = "Username atau Password yang Anda Masukkan Salah";
    }

    if (
      [400].includes(error.response?.status as number) &&
      error.config?.url?.includes("mob_user/transfer_bank/inquiry")
    ) {
      message = "Nama Akun Tidak Ditemukan";
    }
    if (
      [400].includes(error.response?.status as number) &&
      error.config?.url?.includes("digiprod/member/pulsa/inquiry/postpaid")
    ) {
      message = "No Telepon Tidak Ditemukan";
    }
    if (
      [400].includes(error.response?.status as number) &&
      error.config?.url?.includes("mob_user/status_cpm_qrcode")
    ) {
      message = "Kode QR telah kadaluarsa";
    }

    if (
      error.response?.status == 401 &&
      !error.config?.url?.includes("login") &&
      !error.config?.url?.includes("user_change_password")
    ) {
      store.dispatch(removeToken());
      // window.location.href = `/${company?.initial}/login
      // window.location.href = `https://${company.link}`;
    }
    if (
      [403].includes(error.response?.status as number) &&
      !error.config?.url?.includes("mob_user/security_code/reauthenticate")
    ) {
      toast(message, ToastConfig.warning)
    } else if (message == "WRONG_SECURITY_CODE") {
      toast.error("Kode Keamanan Salah");
    }
    if (!message) {
      console.log(message);
    } else {
      toast.error(message);
    }
  }
  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
