import * as API_TRANSACTION from "../core/service/api_transaction";
import * as API_COMMERCE from "../core/service/api_commerce";
import Pos from "../assets/images/icon_home/ic_pos.svg";
import Marketplace from "../assets/images/icon_home/ic_marketplace.svg";
import * as API_MARKETPLACE from "../core/service/api_marketplace";

import Bank from "../assets/images/icon_checkout/Bank.svg";
import Qris from "../assets/images/icon_checkout/Qris.svg";
import Down from "../assets/images/icon_checkout/Down.svg";
import Up from "../assets/images/icon_checkout/Up.png";
import bni from "../assets/images/icon_checkout/bni.svg";
import credit from "../assets/images/icon_checkout/credit.svg";
import bca from "../assets/images/icon_checkout/bca.svg";
import mandiri from "../assets/images/icon_checkout/mandiri.svg";
import bri from "../assets/images/icon_checkout/bri.svg";
import bsi from "../assets/images/icon_checkout/bsi.svg";
import ovo from "../assets/images/icon_checkout/ovo.svg";
import dana from "../assets/images/icon_checkout/dana.svg";
import gopay from "../assets/images/icon_checkout/gopay.svg";
import DKI from "../assets/images/icon_checkout/Bank_dki.svg";
import xendit from "../assets/images/icon_checkout/xendit.svg";
import BalanceIcon from "../assets/images/icon_checkout/Balance.svg";
import linkAjaIcon from "../assets/images/icon_checkout/link_aja.svg";
import BJS from "../assets/images/icon_checkout/BJS.svg";
import { useAppSelector, useAppDispatch } from "../core/feature/hooks";

import moment from "moment";
import { iconBalance } from "./ConfigIcon";
var idLocale = require("moment/locale/id");
const momentLocal = moment.locale("id", idLocale);

export const createArrayEntityFromObject = (obj) => {
  return Object.keys(obj);
};

export const getEntity = (keys, obj) => {
  return keys
    .map((k) => {
      if (typeof obj[k] == "boolean") {
        // console.log("obj[k]", obj[k], "[k]", [k]);
        return obj[k];
        // return { key: [k], value: obj[k] };
      } else {
        if (obj[k]) return { key: [k], value: obj[k] };
      }
    })
    .filter((el) => el != undefined);
};

export const getEntityPy = (keys, obj) => {
  return keys
    .map((k) => {
      if (typeof obj[k] == "boolean" || typeof obj[k] == "number") {
        // console.log("obj[k]", obj[k], "[k]", [k]);
        // return obj[k];
        return { key: [k], value: obj[k] };
      } else {
        if (obj[k]) return { key: [k], value: obj[k] };
      }
    })
    .filter((el) => el != undefined);
};

export const createQueryUrl = (arr, separator) => {
  let query = "";
  arr.map((el, n) => {
    if (arr.length - 1 > n)
      return (query = `${query}${el.key}=${el.value}${separator}`);
    if (typeof el.value == "boolean" || typeof el.value == "number") {
      // console.log("el", el);
      query = `${query}${el.key}=${el.value}`;
    } else if (el.value) {
      query = `${query}${el.key}=${el.value}`;
    }
  });
  // console.log(query);
  return query;
};

export const findArray = (arr, fromKey, value, returnKey) => {
  if (arr.find((el) => el[fromKey].includes(value))) {
    return arr.find((el) => el[fromKey].includes(value))[returnKey];
  } else {
    return null;
  }
};

export const formatCurrency = (value) => {
  if (value == "" || value == null || value == undefined) return "Rp 0";
  return new Intl.NumberFormat("in-IN", {
    style: "currency",
    currency: "IDR",
  }).format(value);
};

export const onlyNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46)
    return evt.preventDefault();
  return true;
};

export const isNumeric = (number) => {
  if (+number === +number) {
    // if is a number
    return true;
  }

  return false;
};

export const backTo = (url, query = {}) => {
  window.location.replace(url);
};

export const checkType = (data) => {
  if (data) {
    if (parseInt(data) != NaN) {
      return parseInt(data);
    }
    return data;
  }
};

export const createNewArray = (array, keys, nested = {}) => {
  let returnArr = [];
  array.map((arr) => {
    let obj = {};
    keys.map((key) => {
      obj[key.to] = arr[key.from];
    });
    if (nested && arr[nested.from]) {
      obj[nested.to] = createNewArray(arr[nested.from], keys, nested);
    }
    returnArr.push(obj);
  });
  return returnArr;
};

export const downloadPdf = (transaction_type, id, query = {}) => {
  console.log(transaction_type);
  if (transaction_type === "marketplace_transactions") {
    API_COMMERCE.downloadPDFMarketplace(id)
      .then((response) => {
        let name = "MARKETPLACE.pdf"; //response.data.data.url.split();
        let url = response.data.url;
        var link = document.createElement("a");

        link.href = url;
        link.target = "_blank";
        link.download = name;
        link.setAttribute("download", `${name}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {});
  } else if (transaction_type === "pencairan_dana_admin") {
    console.log(id);
    API_TRANSACTION.downloadPDFPencairanDana(id)
      .then((response) => {
        console.log(response);
        let name = "PencairanDanaAdmin.pdf"; //response.data.data.url.split();
        let url = response.data.data.url;
        var link = document.createElement("a");
        console.log(response);
        link.href = url;
        link.target = "_blank";
        link.download = name;
        link.setAttribute("download", `${name}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {});
  } else {
    API_TRANSACTION.downloadPDF(transaction_type, id, query)
      .then((response) => {
        console.log(response);
        let name = response.data.data.url.split();
        var link = document.createElement("a");
        link.download = name[name.length - 1];
        link.href = response.data.data.url;
        link.click();
      })
      .catch((err) => {});
  }
};
export const downloadPdfMarketplace = (id) => {
  API_MARKETPLACE.downloadPdf(id)
    .then((res) => {
      // console.log(response.data);
      // const fileURL = window.URL.createObjectURL(response.data);
      // // Setting various property values
      // let alink = document.createElement('a');
      // alink.href = fileURL;
      // alink.download = 'SamplePDF.pdf';
      // alink.click();
      const file = new Blob([res.data], { type: "application/pdf" });

      // process to auto download it
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "FileName" + new Date() + ".pdf";
      link.click();
    })
    .catch((err) => {});
};
export const toMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return moment(date).format("MMMM");
};

export const subStringText = (message, start, end, a) => {
  if (message?.length >= a) {
    return message.substring(start, end) + "...";
  } else {
    return message;
  }
};

export const camelCase = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((el, ind) => {
      if (el) {
        return el[0].toUpperCase() + el.substring(1, el.length);
      }
    })
    .join(" ");
};

export const replaceText = (text, from, to) => {
  return text.replaceAll(from, to);
};

export const humanizedText = (text) => {
  return text.replaceAll("-", " ").replaceAll("_", " ");
};

export const cekToken = () => {
  if (localStorage.getItem("token_redux"))
    return JSON.parse(localStorage.getItem("token_redux").replaceAll('"', ""));
  return "";
};

export const urlFeature = (name) => {
  switch (name) {
    case "POS":
      return "/pos";
    case "MARKETPLACE":
      return "/marketplace";

    default:
      break;
  }
};
// export const nameFeature = (name) => {
//   switch (name) {
//     case 'POS':
//       return 'Pos'
//     case 'MARKETPLACE':
//       return 'Marketplace'
//     case 'TOP_UP_MERCHANT':
//       return null
//     case 'QR_CASH_OUT':
//       return null
//     case 'WITHDRAW_BALANCE':
//       return null
//     case 'SCAN_QR':
//       return null

//     default:
//       break;
//   }
// };

export const statusOrder = (item) => {
  switch (item) {
    case "CREATED":
      return "Order Masuk";
    case "PROCESSED":
      return "Diproses";
    case "CANCELED":
      return "Dibatalkan";
    case "CONFIRMED":
      return "Selesai";
    case "SENT":
      return "Dikirim";
    case "REJECTED":
      return "Ditolak";

    default:
      break;
  }
};

export const formatDateTime = (text) => {
  return moment(text)
    .locale("id")
    .format("DD.MM.YYYY" + " " + "HH:MM");
};
export const formatDate = (text) => {
  return moment(text).locale("id").format("YYYY Do MMMM");
};
export const formatTimeMinute = (time) => {
  return moment(time).locale("id").format("mm:ss");
};
export const formatMonth = (text) => {
  switch (text) {
    case 1:
      return "JANUARI";
    case 2:
      return "FEBRUARI";
    case 3:
      return "MARET";
    case 4:
      return "APRIL";
    case 5:
      return "MEI";
    case 6:
      return "JUNI";
    case 7:
      return "JULI";
    case 8:
      return "AGUSTUS";
    case 9:
      return "SEPTEMBER";
    case 10:
      return "OKTOBER";
    case 11:
      return "NOVEMBER";
    case 12:
      return "DESEMBER";

    default:
      break;
  }
};

export const getBalanceRender = (el, token, balance, LinkAja, DKI, credit) => {
  if (el == "CLOSEPAY" && token)
    return (
      <div>
        <span className="text-[#263339] font-light text-sm">
          {formatCurrency(balance)}
        </span>
      </div>
    );
  if (el == "CREDIT" && token)
    return (
      <div>
        <span className="text-[#263339] font-light text-sm">
          {formatCurrency(credit)}
        </span>
      </div>
    );
  if (el == "LINK_AJA" && LinkAja == "Akun Anda Belum terhubung" && token)
    return <div className="text-xs md:text-sm text-red-400">{LinkAja}</div>;
  if (el == "LINK_AJA" && LinkAja != "Akun Anda Belum terhubung" && token)
    return (
      <img
        className="w-[85%] 2xl:w-[100%]"
        src={`data:image/jpeg;base64,${LinkAja}`}
      />
    );
  if (el == "BANK_DKI" && DKI == "Akun Anda Belum terhubung" && token)
    return <div className="text-xs md:text-sm text-red-400">{DKI}</div>;
  if (el == "BANK_DKI" && DKI != "Akun Anda Belum terhubung" && token)
    return (
      <div>
        <span className="text-[#263339] font-light text-sm">
          {formatCurrency(DKI)}
        </span>
      </div>
    );
};

export const instructionsPaymentBalance = (el) => {
  switch (el) {
    case "CLOSEPAY":
      return bni;
    case "BANK_DKI":
      return bri;
    case "CREDIT":
      return bsi;

    default:
      break;
  }
};

export const ConfigColor = () => {
  const company = useAppSelector((state) => state.company.company);

  var color1 =
    company?.config?.colourPrimary != undefined
      ? company?.config?.colourPrimary
      : "#006F54";
  var color2 =
    company?.config?.colourSecondary != undefined
      ? company?.config?.colourSecondary
      : "#015f48";

  const Color = {
    color1: color1,
    color2: color2,
  };
  return Color;
};

export const hexToRgba = (hexColor, alpha) => {
  const hex = hexColor.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const iconPayment = (el) => {
  // let color= ConfigColor()
  switch (el) {
    case "BNI":
      return bni;
    case "BRI":
      return bri;
    case "BSI":
      return bsi;
    case "BSI_TBG":
      return bsi;
    case "BCA":
      return bca;
    case "MANDIRI":
      return mandiri;
    case "OVO":
      return ovo;
    case "GOPAY":
      return gopay;
    case "DANA":
      return dana;
    case "CLOSEPAY":
      return BalanceIcon;
    case "BANK_DKI":
      return DKI;
    case "XENDIT":
      return xendit;
    case "LINK_AJA":
      return linkAjaIcon;
    case "CREDIT":
      return credit;
    case "BJS":
      return BJS;

    default:
      break;
  }
};

// export const instructionPayment = (el,show) => {
//   switch (el) {
//     case "BRI":
//       return (
//         <div className="space-y-4">
//           <div className="text-sm md:text-lg 2xl:text-xl">
//             <span>Petunjuk Pembayaran</span>
//           </div>
//           <div className="text-sm md:text-lg 2xl:text-xl">
//             <div className="border border-slate-200 rounded-xl">
//               <div
//                 className="grid grid-cols-4 gap-4  dark:bg-slate-900 cursor-pointer hover:text-emerald-400"
//                 onClick={show}
//               >
//                 <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[50px] lg:h-[50px] xl:h-[50px] 2xl:h-[60px] px-4">
//                   {/* <img
//                     src={`${iconPayment(
//                       paymentType.selectedPaymentMethod.provider
//                     )}`}
//                     className="md:w-12 md:h-12 2xl:w-16 2xl:h-16"
//                   /> */}
//                   <div className="text-xs md:text-lg 2xl:text-xl ">
//                     Petunjuk Transfer Melalui M banking
//                   </div>
//                 </div>
//                 <div className="flex justify-end items-center pr-4 cursor-pointer">
//                   <img src={show ? Down : Up} alt="" />
//                 </div>
//               </div>
//               <div
//                 className="bg-[#F2F2F2] px-4 py-3 dark:bg-slate-500 text-slate-400 text-xs space-y-2"
//                 hidden={show}
//               >
//                 <div className="flex items-center space-x-4">
//                   <div className="md:text-lg 2xl:text-xl border-dashed border-b border-slate-400 py-2 w-full">
//                     <span>
//                       <b>Intruksi</b>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     1
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     Masuk ke menu Mobile Banking BRI. Kemudian, pilih
//                     Pembayaran {arrow} BRIVA.
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     2
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Masukkan Nomor BRIVA{" "}
//                       {paymentType.requiredPaymentData.virtualAccountNumber}
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300  p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     3
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Masukkan PIN Anda kemudian pilih Send. Apabila pesan
//                       konfirmasi untuk transaksi menggunakan SMS muncul, pilih
//                       OK. Status transaksi akan dikirimkan melalui SMS dan
//                       dapat digunakan sebagai bukti pembayaran.
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="text-sm md:text-lg 2xl:text-xl">
//             <div className="border border-slate-200 rounded-xl">
//               <div
//                 className="grid grid-cols-4 gap-4  dark:bg-slate-900 cursor-pointer  hover:text-emerald-400"
//                 onClick={actionBniTwo}
//               >
//                 <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[40px] 2xl:h-[60px] px-4">
//                   {/* <img
//                     src={`${iconPayment(
//                       paymentType.selectedPaymentMethod.provider
//                     )}`}
//                     className="md:w-12 md:h-12 2xl:w-16 2xl:h-16"
//                   /> */}
//                   <div className="text-xs md:text-lg 2xl:text-xl ">
//                     Petunjuk Transfer Melalui ATM
//                   </div>
//                 </div>
//                 <div className="flex justify-end items-center pr-4 cursor-pointer">
//                   <img src={BniHideTwo ? Down : Up} alt="" />
//                 </div>
//               </div>
//               <div
//                 className="bg-[#F2F2F2] px-4 py-3 dark:bg-slate-500 text-slate-400 text-xs space-y-2"
//                 hidden={BniHideTwo}
//               >
//                 <div className="flex items-center space-x-4">
//                   <div className="md:text-lg 2xl:text-xl border-dashed border-b border-slate-400 py-2 w-full">
//                     <span>
//                       <b>Intruksi</b>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     1
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     Masukan kartu ATM.
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     2
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Masukan 6 digit PIN ATM.</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300  p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     3
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Pilih menu <b>Transaksi Lainnya</b> {arrow}{" "}
//                       <b>Transfer</b> {arrow} <b>Antar Bank Online</b>.
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     4
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Masukan Kode Bank dan Nomor Virtual Account tujuan.
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     5
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Pastikan nominal yang muncul di layar sudah sesuai
//                       dengan nominal pembelianmu.{" "}
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     6
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pilih OK atau YES </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     7
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Selesai </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="text-sm md:text-lg 2xl:text-xl">
//             <div className="border border-slate-200 rounded-xl">
//               <div
//                 className="grid grid-cols-4 gap-4  dark:bg-slate-900 cursor-pointer  hover:text-emerald-400"
//                 onClick={actionBniThree}
//               >
//                 <div className="col-span-3 flex items-center space-x-4  h-[35px] md:h-[40px] 2xl:h-[60px] px-4">
//                   {/* <img
//                     src={`${iconPayment(
//                       paymentType.selectedPaymentMethod.provider
//                     )}`}
//                     className="md:w-12 md:h-12 2xl:w-16 2xl:h-16"
//                   /> */}
//                   <div className="text-xs md:text-lg 2xl:text-xl ">
//                     Petunjuk Transfer Melalui I Banking
//                   </div>
//                 </div>
//                 <div className="flex justify-end items-center pr-4 cursor-pointer">
//                   <img src={BniHideThree ? Down : Up} alt="" />
//                 </div>
//               </div>
//               <div
//                 className="bg-[#F2F2F2] px-4 py-3 dark:bg-slate-500 text-slate-400 text-xs space-y-2"
//                 hidden={BniHideThree}
//               >
//                 <div className="flex items-center space-x-4">
//                   <div className="md:text-lg 2xl:text-xl border-dashed border-b border-slate-400 py-2 w-full">
//                     <span>
//                       <b>Intruksi</b>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     1
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     Login pada website Bank Online
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     2
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pilihlah menu "Pembayaran"</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     3
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pilihlah menu "Multi Payment"</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     4
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pilih "Penyedia Jasa"</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     5
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Lalu pilih "No Virtual Account" dan Masukan no VA
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     6
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Jika sudah, klik "Tagihan Total", selanjutnya
//                       "Lanjutkan"
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     7
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Masukan Challenge code yang dikirimkan pada Token
//                       Internet Banking anda, selanjutnya "Kirim"
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     8
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pembayaran Selesai</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//     case "MANDIRI":
//       return (
//         <div className="space-y-4">
//           <div className="text-sm md:text-lg 2xl:text-xl">
//             <span>Petunjuk Pembayaran</span>
//           </div>
//           <div className="text-sm md:text-lg 2xl:text-xl">
//             <div className="border border-slate-200 rounded-xl">
//               <div
//                 className="grid grid-cols-4 gap-4  dark:bg-slate-900 cursor-pointer hover:text-emerald-400"
//                 onClick={actionBniOne}
//               >
//                 <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[50px] lg:h-[50px] xl:h-[50px] 2xl:h-[60px] px-4">
//                   <div className="text-xs md:text-lg 2xl:text-xl ">
//                     Petunjuk Transfer Melalui M banking
//                   </div>
//                 </div>
//                 <div className="flex justify-end items-center pr-4 cursor-pointer">
//                   <img src={show ? Down : Up} alt="" />
//                 </div>
//               </div>
//               <div
//                 className="bg-[#F2F2F2] px-4 py-3 dark:bg-slate-500 text-slate-400 text-xs space-y-2"
//                 hidden={show}
//               >
//                 <div className="flex items-center space-x-4">
//                   <div className="md:text-lg 2xl:text-xl border-dashed border-b border-slate-400 py-2 w-full">
//                     <span>
//                       <b>Intruksi</b>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     1
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     Masukan kartu ATM anda dan masukan PIN anda
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     2
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Pilih <b>Menu lain</b> {arrow} <b>Pembayaran</b> {arrow}{" "}
//                       <b>Menu Berikutnya</b> {arrow}{" "}
//                       <b>Pembayaran Lain-lain.</b>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300  p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     3
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Masukan nomor 9000xxxxxxxxxx</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     4
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Masukan jumlah Top Up</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     5
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Konfirmasi transaksi anda</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="text-sm md:text-lg 2xl:text-xl">
//             <div className="border border-slate-200 rounded-xl">
//               <div
//                 className="grid grid-cols-4 gap-4  dark:bg-slate-900 cursor-pointer  hover:text-emerald-400"
//                 onClick={actionBniTwo}
//               >
//                 <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[40px] 2xl:h-[60px] px-4">
//                   {/* <img
//                     src={`${iconPayment(
//                       paymentType.selectedPaymentMethod.provider
//                     )}`}
//                     className="md:w-12 md:h-12 2xl:w-16 2xl:h-16"
//                   /> */}
//                   <div className="text-xs md:text-lg 2xl:text-xl ">
//                     Petunjuk Transfer Melalui ATM
//                   </div>
//                 </div>
//                 <div className="flex justify-end items-center pr-4 cursor-pointer">
//                   <img src={BniHideTwo ? Down : Up} alt="" />
//                 </div>
//               </div>
//               <div
//                 className="bg-[#F2F2F2] px-4 py-3 dark:bg-slate-500 text-slate-400 text-xs space-y-2"
//                 hidden={BniHideTwo}
//               >
//                 <div className="flex items-center space-x-4">
//                   <div className="md:text-lg 2xl:text-xl border-dashed border-b border-slate-400 py-2 w-full">
//                     <span>
//                       <b>Intruksi</b>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     1
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     Masukan kartu ATM.
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     2
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Masukan 6 digit PIN ATM.</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300  p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     3
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Pilih menu <b>Transaksi Lainnya</b> {arrow}{" "}
//                       <b>Transfer</b> {arrow} <b>Antar Bank Online</b>.
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     4
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Masukan Kode Bank dan Nomor Virtual Account tujuan.
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     5
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Pastikan nominal yang muncul di layar sudah sesuai
//                       dengan nominal pembelianmu.{" "}
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     6
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pilih OK atau YES </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     7
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Selesai </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="text-sm md:text-lg 2xl:text-xl">
//             <div className="border border-slate-200 rounded-xl">
//               <div
//                 className="grid grid-cols-4 gap-4  dark:bg-slate-900 cursor-pointer  hover:text-emerald-400"
//                 onClick={actionBniThree}
//               >
//                 <div className="col-span-3 flex items-center space-x-4  h-[35px] md:h-[40px] 2xl:h-[60px] px-4">
//                   {/* <img
//                     src={`${iconPayment(
//                       paymentType.selectedPaymentMethod.provider
//                     )}`}
//                     className="md:w-12 md:h-12 2xl:w-16 2xl:h-16"
//                   /> */}
//                   <div className="text-xs md:text-lg 2xl:text-xl ">
//                     Petunjuk Transfer Melalui I Banking
//                   </div>
//                 </div>
//                 <div className="flex justify-end items-center pr-4 cursor-pointer">
//                   <img src={BniHideThree ? Down : Up} alt="" />
//                 </div>
//               </div>
//               <div
//                 className="bg-[#F2F2F2] px-4 py-3 dark:bg-slate-500 text-slate-400 text-xs space-y-2"
//                 hidden={BniHideThree}
//               >
//                 <div className="flex items-center space-x-4">
//                   <div className="md:text-lg 2xl:text-xl border-dashed border-b border-slate-400 py-2 w-full">
//                     <span>
//                       <b>Intruksi</b>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     1
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     Login pada website Mandiri Online dengan cara memasukkan
//                     user ID dan PIN
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     2
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pilihlah menu "Pembayaran"</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     3
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pilihlah menu "Multi Payment"</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     4
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Silahkan anda pilih "No Rekening Anda"</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     5
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pilih "Penyedia Jasa" </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     6
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Lalu pilih "No Virtual Account" dan Masukan no VA
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     7
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Masukkan "Nomor Virtual Account"</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     8
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Masukkan pada halaman konfirmasi 1</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     9
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Jika sudah, klik "Tagihan Total", selanjutnya
//                       "Lanjutkan"
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     10
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Masuk ke halaman konfirmasi 2</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     11
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Masukkan Challenge code yang dikirimkan pada Token
//                       Internet Banking anda, selanjutnya "Kirim"
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     12
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Anda bisa masuk kehalaman konfirmasi apabila pembayaran
//                       sudah selesai
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       );

//     default:
//       return (
//         <div className="space-y-4">
//           <div className="text-sm md:text-lg 2xl:text-xl">
//             <span>Petunjuk Pembayaran</span>
//           </div>
//           <div className="text-sm md:text-lg 2xl:text-xl">
//             <div className="border border-slate-200 rounded-xl">
//               <div
//                 className="grid grid-cols-4 gap-4  dark:bg-slate-900 cursor-pointer hover:text-emerald-400"
//                 onClick={actionBniOne}
//               >
//                 <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[50px] lg:h-[50px] xl:h-[50px] 2xl:h-[60px] px-4">
//                   <div className="text-xs md:text-lg 2xl:text-xl ">
//                     Petunjuk Transfer Melalui M banking
//                   </div>
//                 </div>
//                 <div className="flex justify-end items-center pr-4 cursor-pointer">
//                   <img src={show ? Down : Up} alt="" />
//                 </div>
//               </div>
//               <div
//                 className="bg-[#F2F2F2] px-4 py-3 dark:bg-slate-500 text-slate-400 text-xs space-y-2"
//                 hidden={show}
//               >
//                 <div className="flex items-center space-x-4">
//                   <div className="md:text-lg 2xl:text-xl border-dashed border-b border-slate-400 py-2 w-full">
//                     <span>
//                       <b>Intruksi</b>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     1
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     Masukan kartu ATM anda dan masukan PIN anda
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     2
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Pilih <b>Menu lain</b> {arrow} <b>Pembayaran</b> {arrow}{" "}
//                       <b>Menu Berikutnya</b> {arrow}{" "}
//                       <b>Pembayaran Lain-lain.</b>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300  p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     3
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Masukan nomor 9000xxxxxxxxxx</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     4
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Masukan jumlah Top Up</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     5
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Konfirmasi transaksi anda</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="text-sm md:text-lg 2xl:text-xl">
//             <div className="border border-slate-200 rounded-xl">
//               <div
//                 className="grid grid-cols-4 gap-4  dark:bg-slate-900 cursor-pointer  hover:text-emerald-400"
//                 onClick={actionBniTwo}
//               >
//                 <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[40px] 2xl:h-[60px] px-4">
//                   <div className="text-xs md:text-lg 2xl:text-xl ">
//                     Petunjuk Transfer Melalui ATM
//                   </div>
//                 </div>
//                 <div className="flex justify-end items-center pr-4 cursor-pointer">
//                   <img src={BniHideTwo ? Down : Up} alt="" />
//                 </div>
//               </div>
//               <div
//                 className="bg-[#F2F2F2] px-4 py-3 dark:bg-slate-500 text-slate-400 text-xs space-y-2"
//                 hidden={BniHideTwo}
//               >
//                 <div className="flex items-center space-x-4">
//                   <div className="md:text-lg 2xl:text-xl border-dashed border-b border-slate-400 py-2 w-full">
//                     <span>
//                       <b>Intruksi</b>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     1
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     Masukan kartu ATM.
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     2
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Masukan 6 digit PIN ATM.</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300  p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     3
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Pilih menu <b>Transaksi Lainnya</b> {arrow}{" "}
//                       <b>Transfer</b> {arrow} <b>Antar Bank Online</b>.
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     4
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Masukan Kode Bank dan Nomor Virtual Account tujuan.
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     5
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Pastikan nominal yang muncul di layar sudah sesuai
//                       dengan nominal pembelianmu.{" "}
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     6
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pilih OK atau YES </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     7
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Selesai </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="text-sm md:text-lg 2xl:text-xl">
//             <div className="border border-slate-200 rounded-xl">
//               <div
//                 className="grid grid-cols-4 gap-4  dark:bg-slate-900 cursor-pointer  hover:text-emerald-400"
//                 onClick={actionBniThree}
//               >
//                 <div className="col-span-3 flex items-center space-x-4  h-[35px] md:h-[40px] 2xl:h-[60px] px-4">
//                   <div className="text-xs md:text-lg 2xl:text-xl ">
//                     Petunjuk Transfer Melalui I Banking
//                   </div>
//                 </div>
//                 <div className="flex justify-end items-center pr-4 cursor-pointer">
//                   <img src={BniHideThree ? Down : Up} alt="" />
//                 </div>
//               </div>
//               <div
//                 className="bg-[#F2F2F2] px-4 py-3 dark:bg-slate-500 text-slate-400 text-xs space-y-2"
//                 hidden={BniHideThree}
//               >
//                 <div className="flex items-center space-x-4">
//                   <div className="md:text-lg 2xl:text-xl border-dashed border-b border-slate-400 py-2 w-full">
//                     <span>
//                       <b>Intruksi</b>
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     1
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     Login pada website Bank Online
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     2
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pilihlah menu "Pembayaran"</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     3
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pilihlah menu "Multi Payment"</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     4
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pilih "Penyedia Jasa"</span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     5
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Lalu pilih "No Virtual Account" dan Masukan no VA
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     6
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Jika sudah, klik "Tagihan Total", selanjutnya
//                       "Lanjutkan"
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     7
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>
//                       Masukan Challenge code yang dikirimkan pada Token
//                       Internet Banking anda, selanjutnya "Kirim"
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-4">
//                   <div className="rounded-full bg-[#2BC569] text-[#FFFFFF] dark:text-slate-300 p-2 md:text-lg 2xl:text-xl w-8 h-8 flex items-center justify-center">
//                     8
//                   </div>
//                   <div className="md:text-lg 2xl:text-xl border-b border-slate-400 py-2 w-full">
//                     <span>Pembayaran Selesai</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//   }
// };
