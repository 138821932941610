import React, { Component, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../core/feature/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Error from "../assets/images/icon_checkout/error.svg";

import { Link, useNavigate, useParams, useLocation } from "react-router-dom";


const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const ErrorPage = () => {
  const title = "Pembayaran";
  document.title = title;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);
  return (
    <>
      <div className="w-full md:w-3/4 2xl:2/5 h-full mx-auto">
        <div className="space-y-8">
          <div className="mt-24">
            <img src={Error} alt="" className="mx-auto w-[100%] md:w-[50%] lg:w-[50%] xl:w-[40%] 2xl:w-[40%]" />
          </div>
          <div className="md:text-xl 2xl:text-2xl text-center space-y-4">
            <div className="text-[#EC1A1A] mx-auto">Maaf Server Sedang Error</div>
            <div className="text-sm md:text-lg 2xl:text-xl">
             Silahkan coba beberapa saat lagi
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
