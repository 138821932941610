import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Down from "../assets/images/icon_checkout/Down.svg";
import failed from "../assets/images/icon_checkout/Failed.svg";
import success from "../assets/images/icon_checkout/Success.svg";
import Up from "../assets/images/icon_checkout/Up.png";
import CancelComponent from "../components/CancelComponent";
import Description from "../components/Description";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import InputPin from "../components/inputPin";
import Config from "../config/Config";
import { ConfigColor, formatCurrency, iconPayment } from "../config/global";
import { setPaymentTypeRedux } from "../core/feature/PaymentType/PaymentType";
import { setLoading } from "../core/feature/config/configSlice";
import { useAppDispatch, useAppSelector } from "../core/feature/hooks";
import {} from "../core/feature/store";
import { removeToken } from "../core/feature/token/tokenSlice";
import { removeUser } from "../core/feature/user/userSlice";
import User from "../core/models/user";
import * as API_PAYMENT from "../core/service/api_payment.js";
import Collapse from "../config/Collapse";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const PaymentBalance = () => {
  const { t } = useTranslation();
  const color = ConfigColor();
  const user: User = useAppSelector((state) => state.user.user);
  const token: String = useAppSelector((state) => state.token.token);
  const company: any = useAppSelector((state) => state.company.company);
  const url =
    company?.userType == "MEMBER"
      ? Config.BaseUrlRedirectMember
      : Config.BaseUrlRedirectMerchant;
  const balanceCode: any = useAppSelector(
    (state) => state.balanceCode.balanceCode
  );
  const paymentType: any = useAppSelector(
    (state) => state.paymentType.paymentType
  );
  const [balance, setbalance] = useState(
    useAppSelector((state) => state.balance.balance)
  );
  const balanceDKI: any = useAppSelector(
    (state) => state.balanceDKI.balanceDKI
  );
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [parseData, setParseData] = useState<any>();
  const [paymentData, setPaymentData] = useState<any>();
  const [BniHideOne, setBniHideOne] = useState(true);
  const [pin, setPin] = useState<any>(["", "", "", "", "", ""]);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(true);
  const [showPin, setShowPin] = useState(true);
  const [alertSuccess, setAlertSuccess] = useState(true);
  const [alertFailed, setAlertFailed] = useState(true);
  const [dateNow, setDateNow] = useState<any>();
  const customSuccesUrl = JSON.parse(
    localStorage.getItem("customSuccessUrl") as any
  );
  const customBackUrl = JSON.parse(
    localStorage.getItem("customBackUrl") as any
  );
  const [max, setMax] = React.useState<any>();

  const title = "Pembayaran";
  document.title = title;

  const submit = async (event: any) => {
    event.preventDefault();
    if (paymentType.selectedPaymentMethod.provider == "BANK_DKI") {
      submitPayment(null);
    } else {
      let securityCode = "";
      let data = {
        securityCode: pin.join(""),
      };
      dispatch(setLoading(true));
      await API_PAYMENT.getPin(data, company.userType.toLowerCase())
        .then((res) => {
          dispatch(setLoading(false));
          securityCode = res.data.data.securityCodeToken;
          setShowPin(true);
          submitPayment(securityCode);
        })
        .catch((err) => {
          setShowPin(true);
          setPin(["", "", "", "", "", ""]);
          setMax("");
          dispatch(setLoading(false));
        });
    }
  };
  const submitPayment = async (securityCode: any) => {
    if (paymentType.selectedPaymentMethod.provider == "BANK_DKI") {
      let data = {
        otp: pin.join(""),
        bankDKIIdTransaction: paymentType.requiredPaymentData.id,
        idCheckout: params.id,
      };
      dispatch(setLoading(true));
      await API_PAYMENT.PaymentDKI(data)
        .then((res) => {
          dispatch(setLoading(false));
          setAlertSuccess(false);
          setMessage(t("alert.message"));
          dispatch(removeToken());
          dispatch(removeUser());
        })
        .catch((err) => {
          setPin(["", "", "", "", "", ""]);
          setMax("");
          dispatch(setLoading(false));
        });
    } else {
      let data = {
        balanceCode: balanceCode,
      };
      let item = {
        id: paymentType.requiredPaymentData.inquiryId,
        securityCodeToken: securityCode,
      };
      dispatch(setLoading(true));
      await API_PAYMENT.Payment(item, data, company.userType.toLowerCase())
        .then((res) => {
          dispatch(setLoading(false));
          setAlertSuccess(false);
          setMessage(t("alert.message"));
          dispatch(removeToken());
          dispatch(removeUser());
        })
        .catch((err) => {
          dispatch(setLoading(false));
          setAlertFailed(false);
          setMessage(t("alert.message"));
          setPin(["", "", "", "", "", ""]);
        });
    }
  };

  const actionBniOne = () => {
    setBniHideOne((state) => !state);
  };

  const cancel = () => {
    setAlert(false);
    setMessage(t("modalCancel.about"));
  };

  const actionCancel = () => {
    dispatch(setPaymentTypeRedux({}));
    localStorage.setItem("choose_payment", false as any);
    if (token) {
      if (customSuccesUrl && customBackUrl) {
        navigate(
          `/${params.id}?access_token=${token}&successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
        );
        dispatch(setPaymentTypeRedux({}));
      } else {
        navigate(`/${params.id}?access_token=${token}`);
        dispatch(setPaymentTypeRedux({}));
      }
    } else {
      if (customSuccesUrl && customBackUrl) {
        navigate(
          `/${params.id}?successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
        );
        dispatch(setPaymentTypeRedux({}));
      } else {
        navigate(`/${params.id}`);
        dispatch(setPaymentTypeRedux({}));
      }
    }
  };

  const getCheckout = async (showLoader = true) => {
    dispatch(setLoading(showLoader));
    await API_PAYMENT.getCheckout(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        setDateNow(Date.now());
        if (res.data.data.status !== "OPEN") {
          localStorage.setItem("choose_payment", false as any);
          if (customSuccesUrl && customBackUrl) {
            navigate(
              `/${params.id}?successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
            );
            dispatch(setPaymentTypeRedux({}));
          } else {
            navigate(`/${params.id}`);
            dispatch(setPaymentTypeRedux({}));
          }
        } else if (res.data.data.selectedPaymentMethod == null || undefined) {
          if (customSuccesUrl && customBackUrl) {
            navigate(
              `/${params.id}?successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
            );
            dispatch(setPaymentTypeRedux({}));
          } else {
            navigate(`/${params.id}`);
            dispatch(setPaymentTypeRedux({}));
          }
        } else {
          dispatch(setPaymentTypeRedux(res.data.data));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const Payment = () => {
    if (paymentType.selectedPaymentMethod.provider == "BANK_DKI") {
      setShowPin(false);
    } else {
      getConfigPin();
    }
  };

  const getConfigPin = async () => {
    await API_PAYMENT.getPinConfig(company.userType.toLowerCase())
      .then((res) => {
        if (res.data.data == false) {
          window.location.href = `${url}/${company.company.initial}/create-security-code?link=${company.link}&companyinitial=${company.company.initial}&token=${token}`;
        } else {
          setShowPin(false);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (paymentType?.status == "OPEN") {
      const loop = setInterval(() => {
        getCheckout(false);
      }, 8000);
      return () => clearInterval(loop);
    } else {
      localStorage.setItem("choose_payment", false as any);
      if (customSuccesUrl && customBackUrl) {
        navigate(
          `/${params.id}?successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
        );
        dispatch(setPaymentTypeRedux({}));
      } else {
        navigate(`/${params.id}`);
        dispatch(setPaymentTypeRedux({}));
      }
    }

    if (paymentType?.selectedPaymentMethod.provider == "LINK_AJA") {
      navigate(`/${params.id}/balance/link-aja`);
    }
  }, []);

  useEffect(() => {
    if (parseData) {
      setPaymentData(parseData.requiredPaymentData);
    }
    setParseData(paymentType);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  useEffect(() => {
    var date = moment.utc(paymentType?.expiredAt).format("YYYY-MM-DD HH:mm:ss");

    var stillUtc = moment.utc(date).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");

    var timeArr = moment(local).format("x");
    let expired = parseInt(timeArr);
    if (dateNow >= expired) {
      if (token) {
        navigate(`/${params.id}?access_token=${token}`);
        dispatch(setPaymentTypeRedux({}));
      } else {
        navigate(`/${params.id}`);
        dispatch(setPaymentTypeRedux({}));
      }
    }
  }, [dateNow]);

  return (
    <>
      <CancelComponent
        show={alert}
        setShow={setAlert}
        message={message}
        submit={actionCancel}
      />
      <InputPin
        input={pin}
        setInput={setPin}
        show={showPin}
        setShow={setShowPin}
        submit={submit}
        width={60}
        height={63}
        margin="0 0.5rem"
        max={max}
        setMax={setMax}
        header={
          paymentType.selectedPaymentMethod.provider !== "BANK_DKI"
            ? t("balance.pinTitle")
            : ""
        }
        message={
          paymentType.selectedPaymentMethod.provider !== "BANK_DKI" ? null : (
            <p className="md:text-2xl text-sm text-black md:mb-4">
              Masukan kode OTP yang telah dikirimkan ke
              <span className="font-bold"> {user.email} </span>
            </p>
          )
        }
      />
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none backdrop-blur-sm focus:outline-none"
        hidden={alertSuccess}
      >
        <div className="animate-top relative mx-auto flex justify-center ">
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col bg-[#FFFFFF] outline-none focus:outline-none rounded-xl  w-[22rem] md:w-[400px] 2xl:w-[500px]">
            {/*body*/}
            <div className="relative space-y-2 p-4 flex flex-col items-center">
              <img src={success} alt="" />
              <div className="text-center text-emerald-400 text-sm md:text-xl ">
                {t("alert.titleSuccess")}
              </div>
              <div className="text-xs md:text-sm 2xl:text-lg text-center">
                {message}
              </div>
            </div>
            <div className="rounded-b px-4 pb-4 space-x-2">
              <div className="flex justify-center">
                <button
                  // style={{
                  //   backgroundColor: `${
                  //     company.config.colourPrimary
                  //       ? company.config.colourPrimary
                  //       : "#0AB185"
                  //   }`,
                  // }}
                  id="success-button"
                  style={{
                    backgroundColor: "#0AB185",
                  }}
                  className=" w-[40%] px-6 mx-auto rounded-lg text-white active:bg-emerald-600 font-bold uppercase text-sm py-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 md:text-lg "
                  type="button"
                  onClick={actionCancel}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none backdrop-blur-sm focus:outline-none"
        hidden={alertFailed}
      >
        <div className="animate-top relative mx-auto flex justify-center ">
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col bg-[#FFFFFF] outline-none focus:outline-none rounded-xl  w-[22rem] md:w-[400px] 2xl:w-[500px]">
            {/*body*/}
            <div className="relative space-y-2 p-4 flex flex-col items-center">
              <img src={failed} alt="" />
              <div className="text-center text-sm md:text-xl ">
                {t("alert.titleFailed")}
              </div>
              <div className="text-xs md:text-sm 2xl:text-lg text-center">
                {message}
              </div>
            </div>
            <div className="rounded-b px-4 pb-4 space-x-2">
              <div className="flex justify-center">
                <button
                  // style={{
                  //   backgroundColor: `${
                  //     company.config.colourPrimary
                  //       ? company.config.colourPrimary
                  //       : "#0AB185"
                  //   }`,
                  // }}
                  // style={{
                  //   backgroundColor: "#0AB185",
                  // }}
                  className=" w-[40%] border-1 border-[#EC1A1A] px-6 mx-auto rounded-lg text-red-500 active:bg-red-400 font-bold uppercase text-sm py-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 md:text-lg "
                  type="button"
                  onClick={actionCancel}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navbar link={cancel} />
      <div className="p-4 md:w-[50%] 2xl:w-[40%] h-full mx-auto text-sm md:text-lg">
        <div className="animate-top space-y-12">
          <Description />
          <div className="border border-slate-200 rounded-xl flex flex-col bg-white">
            <div className="flex items-center space-x-2 p-2 border-b border-slate-200 ">
              <img
                src={`${iconPayment(
                  paymentType.selectedPaymentMethod.provider
                )}`}
                alt=""
                className="h-12 w-12"
              />
              <div>
                {paymentType
                  ? paymentType.selectedPaymentMethod.provider.replaceAll(
                      "_",
                      " "
                    )
                  : "Saldo utama"}
              </div>
            </div>
            {paymentType.selectedPaymentMethod.provider == "CLOSEPAY" ? (
              <div className=" space-y-2 ">
                <div
                  className="px-4 py-2  text-white"
                  style={{
                    backgroundColor: color.color1,
                  }}
                >
                  <span className="text-base">{t("balance.mySaldo")}</span>
                  <div className="flex items-center space-x-4">
                    <span className="text-white text-xl font-semibold">
                      {formatCurrency(balance)}
                    </span>
                  </div>
                </div>
                <div className="px-4 pb-3 space-y-2">
                  <span>{t("balance.nominalPay")}</span>
                  <div className="flex items-center space-x-4">
                    <span
                      style={{
                        color: color.color1,
                      }}
                    >
                      {formatCurrency(parseData ? parseData.amount : 0)}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className=" space-y-4 ">
                <div
                  hidden={
                    paymentType.selectedPaymentMethod.provider == "CREDIT"
                  }
                  className="px-4 py-2 space-y-2 bg-[#00D19F] text-white"
                >
                  <span className="text-base">Saldoku </span>
                  <div className="flex items-center space-x-4">
                    <span className="text-white text-xl font-semibold">
                      {formatCurrency(balanceDKI)}
                    </span>
                  </div>
                </div>
                <div className="px-4 pb-3 space-y-2">
                  <span>Nominal </span>
                  <div className="flex items-center space-x-4">
                    <span className="text-emerald-400">
                      {formatCurrency(parseData ? parseData.amount : 0)}
                    </span>
                  </div>
                </div>
                <div
                  hidden={
                    paymentType.selectedPaymentMethod.provider == "CREDIT"
                  }
                  className="px-4 pt-2 pb-3 space-y-2"
                >
                  <span>Biaya Admin</span>
                  <div className="flex items-center space-x-4">
                    <span className="text-emerald-400">
                      {formatCurrency(
                        parseData
                          ? parseData.requiredPaymentData?.amount -
                              parseData.amount
                          : 0
                      )}
                    </span>
                  </div>
                </div>
                <div
                  hidden={
                    paymentType.selectedPaymentMethod.provider == "CREDIT"
                  }
                  className="px-4 pt-2 pb-3 space-y-2"
                >
                  <span>Nominal Yang Harus Dibayarkan</span>
                  <div className="flex items-center space-x-4">
                    <span className="text-emerald-400">
                      {formatCurrency(
                        parseData ? parseData.requiredPaymentData?.amount : 0
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="space-y-4">
            <div>
              <span>{t("global.paymentInstructions")}</span>
            </div>
            <div>
              <div className="border border-slate-200 rounded-xl bg-white">
                <div
                  className="grid grid-cols-4 gap-4 rounded  cursor-pointer hover:text-emerald-400"
                  onClick={actionBniOne}
                >
                  <div className="col-span-3 flex items-center space-x-4 h-[44px] md:h-[50px] lg:h-[50px] xl:h-[50px] px-2">
                    <img
                      src={`${iconPayment(
                        paymentType.selectedPaymentMethod.provider
                      )}`}
                      className="md:w-12 md:h-12 2xl:w-16 2xl:h-16"
                    />
                    <div>{t("balance.Instructions")}</div>
                  </div>
                  <div className="flex justify-end items-center pr-4 cursor-pointer">
                    <img src={BniHideOne ? Down : Up} alt="" />
                  </div>
                </div>
                <Collapse open={!BniHideOne} timeout="auto" unmountOnExit>
                  <div className="bg-[#F4F6F8] p-2 text-slate-400 text-xs space-y-2">
                    <div className="flex items-center space-x-4">
                      <div className="md:text-lg  border-dashed border-b border-slate-400 py-2 w-full">
                        <span>
                          <b>{t("global.Instructions")}</b>
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF] p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{
                          backgroundColor: color.color1,
                        }}
                      >
                        1
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        {t("balance.one")}
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF] p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{
                          backgroundColor: color.color1,
                        }}
                      >
                        2
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        <span> {t("balance.two")}</span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF] p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{
                          backgroundColor: color.color1,
                        }}
                      >
                        3
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        {paymentType.selectedPaymentMethod.provider !==
                        "BANK_DKI" ? (
                          <span> {t("balance.three")}</span>
                        ) : (
                          <span>
                            {t("balance.threeDKI")} <b>{user.email}</b>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div
                        className="rounded-full  text-[#FFFFFF] p-2 md:text-lg  w-8 h-8 flex items-center justify-center"
                        style={{
                          backgroundColor: color.color1,
                        }}
                      >
                        4
                      </div>
                      <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                        <span>Klik OK</span>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>

          <div className=" w-full mt-14 space-y-4 py-2 pb-4 mx-auto">
            <div className="flex justify-center">
              <button
                style={{
                  backgroundColor: color.color1,
                }}
                className="flex items-center justify-center w-full h-[40px]  md:h-[40px] px-3 py-2 rounded-lg text-[#FFFFFF] active:bg-emerald-600"
                onClick={Payment}
              >
                {t("global.buttonPayDetail")}
              </button>
            </div>
            <div className="flex justify-center">
              <button
                className="bg-[#FFFFFF] w-full h-[40px] md:h-[40px] rounded-lg border-1 border-[#9A9999] text-[#9A9999] active:bg-slate-200 active:text-[#FFFFFF]"
                onClick={cancel}
              >
                {t("global.buttonCancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentBalance;
