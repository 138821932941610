import React, { Component, useEffect, useState } from "react";
import {} from "../core/feature/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const NotFound = () => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="h-screen flex items-center justify-center">
        <div className="text-2xl text-slate-500 italic">Page Not Found</div>
      </div>
    </>
  );
};

export default NotFound;
