const resp = {
  instructions_va: [
    {
      id: "mBanking",
      title: "Petunjuk Transfer Melalui M banking",
      intruction: [
        {
          id: "1",
          idlevel1: "mBanking",
          title: "Buka dan masuk ke aplikasi M-banking",
        },
        {
          id: "2",
          idlevel1: "mBanking",
          title: "Pilih menu Transfer atau Transfer Dana",
        },
        { id: "3", idlevel1: "mBanking", title: "Pilih rekening asal" },
        {
          id: "4",
          idlevel1: "mBanking",
          title: "Pilih opsi Transfer ke Virtual Account pada tujuan transfer",
        },
        {
          id: "5",
          idlevel1: "mBanking",
          title: "Masukkan nomor virtual account {get_VA}",
        },
        {
          id: "6",
          idlevel1: "mBanking",
          title:
            "Aplikasi akan menampilkan informasi tentang transfer, termasuk nomor VA tujuan dan jumlah yang akan ditransfer. Pastikan informasi ini benar sebelum melanjutkan.",
        },
        {
          id: "7",
          idlevel1: "mBanking",
          title: "Konfirmasi transaksi.",
        },
      ],
    },
    {
      id: "ATM",
      title: "Petunjuk Transfer Melalui ATM",
      intruction: [
        {
          id: "1",
          idlevel1: "ATM",
          title: "Masukkan kartu ATM ke mesin ATM",
        },
        {
          id: "2",
          idlevel1: "ATM",
          title: "Pilih Bahasa",
        },
        { id: "3", idlevel1: "ATM", title: " Pilih rekening asal" },
        {
          id: "4",
          idlevel1: "ATM",
          title: `Pilih opsi "Ke Rekening BRI" pada pilihan jenis transfer`,
        },
        {
          id: "5",
          idlevel1: "ATM",
          title: "Masukkan nomor virtual account {get_VA}",
        },
        {
          id: "6",
          idlevel1: "ATM",
          title:
            "Mesin ATM akan menampilkan informasi tentang pembayaran, termasuk nomor VA Fixed Payment dan jumlah yang akan dibayarkan. Pastikan informasi ini benar sebelum melanjutkan.",
        },
        {
          id: "7",
          idlevel1: "ATM",
          title: "Konfirmasi transaksi.",
        },
      ],
    },
    {
      id: "internet",
      title: "Petunjuk Transfer Melalui I Banking",
      intruction: [
        {
          id: "1",
          idlevel1: "internet",
          title: "Akses dan masuk ke aplikasi internet banking",
        },
        {
          id: "2",
          idlevel1: "internet",
          title: "Pilih menu Transfer atau Transaksi",
        },
        { id: "3", idlevel1: "internet", title: "Pilih rekening asal" },
        {
          id: "4",
          idlevel1: "internet",
          title: `Pilih tujuan transfer ke virtual account`,
        },
        {
          id: "5",
          idlevel1: "internet",
          title: "Masukkan nomor virtual account {get_VA}",
        },
        {
          id: "6",
          idlevel1: "internet",
          title:
            "Platform Internet Banking akan menampilkan informasi tentang transfer, termasuk nomor VA tujuan dan jumlah yang akan ditransfer. Pastikan semua informasi benar sebelum melanjutkan.",
        },
        {
          id: "7",
          idlevel1: "internet",
          title: "Konfirmasi transaksi.",
        },
      ],
    },
  ],
  instructions_va: [
    {
      id: "mBanking",
      title: "Petunjuk Transfer Melalui M banking",
      intruction: [
        {
          id: "1",
          idlevel1: "mBanking",
          title: "Buka dan masuk ke aplikasi M-banking",
        },
        {
          id: "2",
          idlevel1: "mBanking",
          title: "Pilih menu Transfer atau Transfer Dana",
        },
        { id: "3", idlevel1: "mBanking", title: "Pilih rekening asal" },
        {
          id: "4",
          idlevel1: "mBanking",
          title: "Pilih opsi Transfer ke Virtual Account pada tujuan transfer",
        },
        {
          id: "5",
          idlevel1: "mBanking",
          title: "Masukkan nomor virtual account {get_VA}",
        },
        {
          id: "6",
          idlevel1: "mBanking",
          title:
            "Aplikasi akan menampilkan informasi tentang transfer, termasuk nomor VA tujuan dan jumlah yang akan ditransfer. Pastikan informasi ini benar sebelum melanjutkan.",
        },
        {
          id: "7",
          idlevel1: "mBanking",
          title: "Konfirmasi transaksi.",
        },
      ],
    },
    {
      id: "ATM",
      title: "Petunjuk Transfer Melalui ATM",
      intruction: [
        {
          id: "1",
          idlevel1: "ATM",
          title: "Masukkan kartu ATM ke mesin ATM",
        },
        {
          id: "2",
          idlevel1: "ATM",
          title: "Pilih Bahasa",
        },
        { id: "3", idlevel1: "ATM", title: " Pilih rekening asal" },
        {
          id: "4",
          idlevel1: "ATM",
          title: `Pilih opsi "Ke Rekening BRI" pada pilihan jenis transfer`,
        },
        {
          id: "5",
          idlevel1: "ATM",
          title: "Masukkan nomor virtual account {get_VA}",
        },
        {
          id: "6",
          idlevel1: "ATM",
          title:
            "Mesin ATM akan menampilkan informasi tentang pembayaran, termasuk nomor VA Fixed Payment dan jumlah yang akan dibayarkan. Pastikan informasi ini benar sebelum melanjutkan.",
        },
        {
          id: "7",
          idlevel1: "ATM",
          title: "Konfirmasi transaksi.",
        },
      ],
    },
    {
      id: "internet",
      title: "Petunjuk Transfer Melalui I Banking",
      intruction: [
        {
          id: "1",
          idlevel1: "internet",
          title: "Akses dan masuk ke aplikasi internet banking",
        },
        {
          id: "2",
          idlevel1: "internet",
          title: "Pilih menu Transfer atau Transaksi",
        },
        { id: "3", idlevel1: "internet", title: "Pilih rekening asal" },
        {
          id: "4",
          idlevel1: "internet",
          title: `Pilih tujuan transfer ke virtual account`,
        },
        {
          id: "5",
          idlevel1: "internet",
          title: "Masukkan nomor virtual account {get_VA}",
        },
        {
          id: "6",
          idlevel1: "internet",
          title:
            "Platform Internet Banking akan menampilkan informasi tentang transfer, termasuk nomor VA tujuan dan jumlah yang akan ditransfer. Pastikan semua informasi benar sebelum melanjutkan.",
        },
        {
          id: "7",
          idlevel1: "internet",
          title: "Konfirmasi transaksi.",
        },
      ],
    },
  ],
  instructions_va_BSI: [
    {
      id: "mBanking",
      title: "Petunjuk Transfer Melalui M banking",
      intruction: [
        {
          id: "1",
          idlevel1: "mBanking",
          title: "Login ke aplikasi Mobile Banking BSI Anda",
        },
        {
          id: "2",
          idlevel1: "mBanking",
          title: "Pilih menu Bayar",
        },
        { id: "3", idlevel1: "mBanking", title: "Pilih Institusi" },
        {
          id: "4",
          idlevel1: "mBanking",
          title: "Masukkkan kode institusi Anda dengan mengetikkan 4 digit karakter pertama Virtual Account Anda yaitu {kode_institusi}",
        },
        {
          id: "5",
          idlevel1: "mBanking",
          title: "Masukkan kode pembayaran Anda berupa Virtual Account Anda dengan menghapus 4 digit karakter pertama Virtual Account Anda yaitu {get_VA_BSI}",
        },
        {
          id: "6",
          idlevel1: "mBanking",
          title:
            "Klik selanjutnya",
        },
        {
          id: "7",
          idlevel1: "mBanking",
          title: "Masukkan nominal transfer",
        },
        {
          id: "8",
          idlevel1: "mBanking",
          title: "Masukkan PIN",
        },
        {
          id: "9",
          idlevel1: "mBanking",
          title: "Konfirmasi pembayaran",
        },
      ],
    },
    {
      id: "ATM",
      title: "Petunjuk Transfer Melalui ATM",
      intruction: [
        {
          id: "1",
          idlevel1: "ATM",
          title: "Masukkan kartu ATM ke mesin ATM",
        },
        {
          id: "2",
          idlevel1: "ATM",
          title: "Pilih Bahasa",
        },
        { id: "3", idlevel1: "ATM", title: " Pilih rekening asal" },
        {
          id: "4",
          idlevel1: "ATM",
          title: `Pilih opsi "Ke Rekening BRI" pada pilihan jenis transfer`,
        },
        {
          id: "5",
          idlevel1: "ATM",
          title: "Masukkan nomor virtual account {get_VA}",
        },
        {
          id: "6",
          idlevel1: "ATM",
          title:
            "Mesin ATM akan menampilkan informasi tentang pembayaran, termasuk nomor VA Fixed Payment dan jumlah yang akan dibayarkan. Pastikan informasi ini benar sebelum melanjutkan.",
        },
        {
          id: "7",
          idlevel1: "ATM",
          title: "Konfirmasi transaksi.",
        },
      ],
    },
    {
      id: "internet",
      title: "Petunjuk Transfer Melalui I Banking",
      intruction: [
        {
          id: "1",
          idlevel1: "internet",
          title: "Akses dan masuk ke aplikasi internet banking",
        },
        {
          id: "2",
          idlevel1: "internet",
          title: "Pilih menu Transfer atau Transaksi",
        },
        { id: "3", idlevel1: "internet", title: "Pilih rekening asal" },
        {
          id: "4",
          idlevel1: "internet",
          title: `Pilih tujuan transfer ke virtual account`,
        },
        {
          id: "5",
          idlevel1: "internet",
          title: "Masukkan nomor virtual account {get_VA}",
        },
        {
          id: "6",
          idlevel1: "internet",
          title:
            "Platform Internet Banking akan menampilkan informasi tentang transfer, termasuk nomor VA tujuan dan jumlah yang akan ditransfer. Pastikan semua informasi benar sebelum melanjutkan.",
        },
        {
          id: "7",
          idlevel1: "internet",
          title: "Konfirmasi transaksi.",
        },
      ],
    },
  ],
};

export default resp;
