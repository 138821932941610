import React, { useEffect, useState } from "react";
import { setCompany } from "../core/feature/company/companySlice";
import { useAppDispatch, useAppSelector } from "../core/feature/hooks";
import {} from "../core/feature/store";
import * as API from "../core/service/api.js";
import * as API_BALANCE from "../core/service/api_balance.js";
import * as API_PAYMENT from "../core/service/api_payment.js";

import Down from "../assets/images/icon_checkout/Down.svg";
import Up from "../assets/images/icon_checkout/Up.png";
import Navbar from "../components/Navbar";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setBalanceCode } from "../core/feature/BalanceCode/BalanceCode";
import { setBalanceCredit } from "../core/feature/balanceCredit/balanceCreditSlice";
import { setBalanceDKI } from "../core/feature/BalanceDKI/BalanceDKI";
import { setBalanceLinkAja } from "../core/feature/BalanceLinkAja/BalanceLinkAja";
import { setPaymentTypeRedux } from "../core/feature/PaymentType/PaymentType";
import { removeToken, setToken } from "../core/feature/token/tokenSlice";
import { setBalance } from "../core/feature/user/balanceSlice";

import moment from "moment";
import Countdown, { zeroPad } from "react-countdown";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Description from "../components/Description";
import Footer from "../components/Footer";
import InputPin from "../components/inputPin";
import Collapse from "../config/Collapse";
import Config from "../config/Config";
import { iconBalance, iconBank, iconQris } from "../config/ConfigIcon";
import {
  ConfigColor,
  formatCurrency,
  getBalanceRender,
  hexToRgba,
  iconPayment,
} from "../config/global";
import ToastConfig from "../config/Toast";
import { setLoading } from "../core/feature/config/configSlice";
import { removeUser, setUser } from "../core/feature/user/userSlice";
import User from "../core/models/user";
import ErrorPage from "./ErrorPage";
import Expired from "./Expired";
import NotFoundCO from "./NotFoundCO";
import Paid from "./Paid";
import Refunded from "./Refunded";

const Payment = () => {
  const { t } = useTranslation();
  const color = ConfigColor();
  const paymentType: any = useAppSelector(
    (state) => state.paymentType.paymentType
  );
  const balance: any = useAppSelector((state) => state.balance.balance);
  const balanceLinkAja: any = useAppSelector(
    (state) => state.balanceLinkAja.balanceLinkAja
  );
  const balanceDKI: any = useAppSelector(
    (state) => state.balanceDKI.balanceDKI
  );
  const balanceCredit: any = useAppSelector(
    (state) => state.balanceCredit.balanceCredit
  );
  const company: any = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const token: any = useAppSelector((state) => state.token.token);
  const balanceCode: any = useAppSelector(
    (state) => state.balanceCode.balanceCode
  );
  const url =
    company?.userType == "MEMBER"
      ? Config.BaseUrlRedirectMember
      : Config.BaseUrlRedirectMerchant;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState<any>();
  const [PaymentTypeRadio, setPaymentTypeRadio] = useState<any>("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(true);
  const [HideBalance, setHideBalance] = useState(false);
  const [HideBanks, setHideBanks] = useState(false);
  const [HideQris, setHideQris] = useState(false);
  const [methodBank, setMethodBank] = useState("");
  const [methodQris, setMethodQris] = useState("");
  const [methodBalance, setMethodBalance] = useState("");
  const [qrisOptions, setQrisOptions] = useState([]);
  const [balanceOptions, setBalanceOptions] = useState([]);
  const [vaOptions, setVaOptions] = useState([]);
  const [countdown, setCountdown] = useState(false);
  const [expired, setExpired] = useState("");
  const [timer, setTimer] = useState<any>(0);
  const [balanceNoHideDesc, setBalanceNoHideDesc] = useState(false);
  const [showPaid, setShowPaid] = useState(true);
  const [showPayment, setShowPayment] = useState(true);
  const [showExpired, setShowExpired] = useState(true);
  const [showErrorPage, setErrorPage] = useState(true);
  const [showNotFound, setShowNotFound] = useState(true);
  const [refunded, setRefunded] = useState(true);
  const [showPin, setShowPin] = useState(true);
  const [pin, setPin] = useState<any>(["", "", "", "", "", ""]);
  const [max, setMax] = React.useState<any>();
  let choose_payment = localStorage.getItem("choose_payment");

  const title = "Pembayaran";
  document.title = title;

  const getCheckout = async (showLoader: Boolean = true) => {
    dispatch(setLoading(showLoader));
    await API_PAYMENT.getCheckout(params.id)
      .then((res) => {
        let selected = res.data.data.selectedPaymentMethod;
        let available = res.data.data.availablePaymentMethod?.balanceOptions;
        dispatch(setLoading(false));
        setData(res.data.data);
        setBalanceOptions(res.data.data.availablePaymentMethod.balanceOptions);
        setVaOptions(res.data.data.availablePaymentMethod.vaOptions);
        setQrisOptions(res.data.data.availablePaymentMethod.qrisOptions);
        dispatch(setCompany(res.data.data));
        var date = moment
          .utc(res.data.data.expiredAt)
          .format("YYYY-MM-DD HH:mm:ss");
        var stillUtc = moment.utc(date).toDate();
        var local = moment(stillUtc).local().format("LL HH:mm");
        setExpired(local);
        checkBalance(available);
        if (checkPayment(selected, res.data.data))
          dispatch(setPaymentTypeRedux(res.data.data));
      })
      .catch((err) => {
        const firstDigitStr: any = String(err.response.status)[0];
        if (firstDigitStr == 4) {
          setShowNotFound(false);
          setShowPayment(true);
          setShowPaid(true);
          setShowExpired(true);
          setErrorPage(true);
        }
        if (firstDigitStr == 5) {
          setShowNotFound(true);
          setShowPayment(true);
          setShowPaid(true);
          setShowExpired(true);
          setErrorPage(false);
        }
        dispatch(setLoading(false));
        if (
          err.response.data.detail.type ==
          "CHECKOUT_USER_ID_MUST_BE_NULL_IF_ACCESSED_BY_GUEST"
        ) {
          setAlert(false);
          setMessage("Anda harus login terlebih dahulu !");
        }
      });
  };

  const checkBalance = async (data: any) => {
    // if (!getToken || !token) return;

    if (data.includes("LINK_AJA")) {
      getBalanceLinkAja(false);
    }
    if (data.includes("CLOSEPAY")) {
      getBalance(false);
    }
    if (data.includes("CREDIT")) {
      getBalanceCredit(false);
    }
    if (data.includes("BANK_DKI")) {
      getBalanceDKI(false);
    }
  };

  const checkPayment = (data: Object, item: any) => {
    var date = moment.utc(item?.expiredAt).format("YYYY-MM-DD HH:mm:ss");

    var stillUtc = moment.utc(date).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");

    var timeArr = moment(local).format("x");
    let expired = parseInt(timeArr);

    let check = false;

    if (choose_payment === "true" && data != null) {
      check = true;
    }
    if (choose_payment === null && data != null) {
      check = true;
    }
    if (Date.now() >= expired) {
      check = false;
    }

    return check;
  };
  const getBalance = async (loader: Boolean = true) => {
    dispatch(setLoading(loader));
    let balanceCode = "closepay";
    await API_BALANCE.getBalance(balanceCode, company.userType.toLowerCase())
      .then((res) => {
        if (company.userType == "MEMBER") {
          dispatch(setBalance(parseInt(res.data.data.amount)));
        } else {
          dispatch(setBalance(parseInt(res.data.amount)));
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getBalanceCredit = async (loader: Boolean = true) => {
    dispatch(setLoading(loader));
    let balanceCode = "credit";
    await API_BALANCE.getBalance(balanceCode, company.userType.toLowerCase())
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setBalanceCredit(res.data.data.amount));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getBalanceLinkAja = async (loader: Boolean = true) => {
    let message: any = "Akun Anda Belum terhubung";
    if (company?.userType?.toLowerCase() === "merchant") {
      return dispatch(setBalanceLinkAja(message));
    }
    dispatch(setLoading(loader));
    await API_BALANCE.getBalanceLinkAJa()
      .then((res) => {
        dispatch(setBalanceLinkAja(res.data.data.amount));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.response.data.detail.type == "LINK_AJA_NOT_CONNECTED")
          return dispatch(setBalanceLinkAja(message));
      });
  };

  const getBalanceDKI = async (loader: Boolean = true) => {
    let message: any = "Akun Anda Belum terhubung";
    if (company?.userType?.toLowerCase() === "merchant") {
      return dispatch(setBalanceDKI(message));
    }
    dispatch(setLoading(loader));
    await API_BALANCE.getBalanceDKI()
      .then((res) => {
        dispatch(setBalanceDKI(res.data.amount));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.response.data.detail.type == "BANK_DKI_ACCOUNT_NOT_CONNECTED")
          return dispatch(setBalanceDKI(message));
      });
  };
  const methodPayment = () => {
    let check = true;
    if (methodBalance) {
      check = false;
    }
    if (methodBank) {
      check = false;
    }
    if (methodQris) {
      check = false;
    }
    return check;
  };

  const checkToken = async () => {
    dispatch(setLoading(true));
    await API.cekToken(company.userType.toLowerCase())
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setUser(res.data.data));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(removeUser());
        dispatch(removeToken());
        if (err.response?.status == 401) {
          setAlert(true);
        }
      });
  };

  const submitDKI = async (event: any) => {
    event.preventDefault();
    if (!pin) {
      // toast.error("Anda belum memasukkan Password");
      setShowPin(true);
    } else {
      let data = {
        paymentType: "BALANCE",
        provider: methodBalance,
        password: pin.join(""),
      };
      await API_PAYMENT.paymentTypeToken(params.id, data)
        .then((res) => {
          dispatch(setLoading(false));
          setShowPin(true);
          dispatch(setPaymentTypeRedux(res.data.data));
          toast(
            `Cek email anda ${user.email} untuk mendapatkan kode otp`,
            ToastConfig.info
          );
          // toast.info(`Cek email anda ${user.email} untuk mendapatkan kode otp`);
          if (company.selectedPaymentMethod.paymentType == "BALANCE") {
            navigate(`/${params.id}/balance`, {
              state: {
                data: JSON.stringify(methodBalance),
              },
              replace: true,
            });
          }
          if (res.data.data.selectedPaymentMethod.paymentType == "VA") {
            navigate(`/${params.id}/bank`, {
              state: {
                data: JSON.stringify(res.data.data),
              },
            });
          }
          if (res.data.data.selectedPaymentMethod.paymentType == "QRIS") {
            navigate(`/${params.id}/qris`, {
              state: {
                data: JSON.stringify(methodBalance),
              },
              replace: true,
            });
          }
        })
        .catch((err) => {
          dispatch(setLoading(false));
          setShowPin(true);
          setPin(["", "", "", "", "", ""]);
          setMax("");
          if (err.response.status == 422) {
            toast.error("Pilih Metode Pembayaran");
          }
        });
    }
  };
  const submit = async (event: any) => {
    event.preventDefault();
    dispatch(setLoading(true));
    var tempPaymentType: any;

    tempPaymentType = PaymentTypeRadio;
    if (methodBalance || methodBank || methodQris) {
      if (methodBalance == "BANK_DKI") {
        dispatch(setLoading(false));
        setShowPin(false);
        tempPaymentType = "BALANCE";
      } else {
        if (methodBalance) {
          tempPaymentType = "BALANCE";
        } else if (methodBank) {
          tempPaymentType = "VA";
        } else if (methodQris) {
          tempPaymentType = "QRIS";
        }
      }
    }
    setPaymentTypeRadio(tempPaymentType);
    let tempMethodBalance: any;
    tempMethodBalance = methodBalance;
    let data;
    if (tempPaymentType == "BALANCE") {
      if (methodBalance == "BANK_DKI") {
        data = {
          paymentType: tempPaymentType,
          provider: "BANK_DKI",
          password: pin,
        };
      } else {
        data = {
          paymentType: tempPaymentType,
          provider: methodBalance,
        };
      }
    } else if (methodBank) {
      data = {
        paymentType: tempPaymentType,
        provider: methodBank,
      };
    } else if (methodBank) {
      data = {
        paymentType: tempPaymentType,
        provider: methodBank,
      };
    } else if (methodQris) {
      data = {
        paymentType: tempPaymentType,
        provider: methodQris,
      };
    }

    dispatch(setBalanceCode(tempMethodBalance));
    if (methodBalance != "BANK_DKI") {
      if (token) {
        await API_PAYMENT.paymentTypeToken(
          params.id,
          data,
          company.userType.toLowerCase()
        )
          .then((res) => {
            dispatch(setLoading(false));
            dispatch(setPaymentTypeRedux(res.data.data));
            localStorage.setItem(
              "pgpToken",
              JSON.stringify(res.data.data.requiredPaymentData.token)
            );
            if (company.selectedPaymentMethod.paymentType == "BALANCE") {
              navigate(`/${params.id}/balance`, {
                state: {
                  data: JSON.stringify(methodBalance),
                },
                replace: true,
              });
            }
            if (res.data.data.selectedPaymentMethod.paymentType == "VA") {
              navigate(`/${params.id}/bank`, {
                state: {
                  data: JSON.stringify(res.data.data),
                },
              });
            }
            if (res.data.data.selectedPaymentMethod.paymentType == "QRIS") {
              navigate(`/${params.id}/qris`, {
                state: {
                  data: JSON.stringify(methodBalance),
                },
                replace: true,
              });
            }
          })
          .catch((err) => {
            dispatch(setLoading(false));
            if (err.response.status == 422) {
              toast.error("Pilih Metode Pembayaran");
            }
          });
      } else if (!token) {
        await API_PAYMENT.paymentType(
          params.id,
          data,
          company.userType.toLowerCase()
        )
          .then((res) => {
            dispatch(setLoading(false));
            dispatch(setPaymentTypeRedux(res.data.data));
            if (res.data.data.selectedPaymentMethod.paymentType == "BALANCE") {
              navigate(`/${params.id}/balance`, {
                state: {
                  data: JSON.stringify(methodBalance),
                },
                replace: true,
              });
            }
            if (res.data.data.selectedPaymentMethod.paymentType == "VA") {
              navigate(`/${params.id}/bank`, {
                state: {
                  data: JSON.stringify(res.data.data),
                },
              });
            }
            if (res.data.data.selectedPaymentMethod.paymentType == "QRIS") {
              navigate(`/${params.id}/qris`, {
                state: {
                  data: JSON.stringify(methodBalance),
                },
                replace: true,
              });
            }
          })
          .catch((err) => {
            dispatch(setLoading(false));
            if (err.response.status == 422) {
              toast.error("Pilih Metode Pembayaran");
            }
          });
      }
    }
  };
  // the query string for you.
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let getToken = query.get("access_token");
  let getSuccessUrl = query.get("successRedirectUrl");
  let getBackRedirectUrl = query.get("backRedirectUrl");
  let getBaseUrl = query.get("baseUrl");

  const renderer = (
    // { hours, minutes, seconds, completed }
    data: any
  ) => {
    if (data.completed && refunded) {
      setCountdown(false);
      setShowNotFound(true);
      setShowPayment(true);
      setShowPaid(true);
      setShowExpired(false);
      setErrorPage(true);

      // navigate("/expired");
      return (
        <span
          className=""
          style={{
            fontFamily: "Poppins-500",
            color: "#26BF64",
            fontSize: 20,
          }}
        >
          Waktu Habis
        </span>
      );
    } else {
      setCountdown(true);
      // Render a countdown
      let countdown = {
        days: zeroPad(data.days),
        hours: zeroPad(data.hours),
        minutes: zeroPad(data.minutes),
        seconds: zeroPad(data.seconds),
      };
      return (
        <span className="text-sm md:text-lg 2xl:text-xl text-center font-bold">
          {countdown.days == "00"
            ? ""
            : countdown.days + " " + t("home.day") + " " + " "}
          {countdown.hours == "00"
            ? ""
            : countdown.hours + " " + t("home.hour") + " " + " "}
          {countdown.minutes == "00"
            ? ""
            : countdown.minutes + " " + t("home.minute") + " " + " "}
          {countdown.seconds == "00"
            ? ""
            : countdown.seconds + " " + t("home.second")}
        </span>
      );
    }
  };

  const goToLogin = () => {
    window.location.href = `${url}/${company.company.initial}/login?link=${company.link}&initialcompany=${company.company.initial}&checkoutlogin=${params.id}`;
  };
  const balanceDelete = () => {
    setMethodBank("");
    setMethodQris("");
  };
  const bankDelete = () => {
    setMethodBalance("");
    setMethodQris("");
  };
  const qrisDelete = () => {
    setMethodBalance("");
    setMethodBank("");
  };
  const renderPage = () => {
    let tempPaymentType = paymentType;
    if (Object.keys(tempPaymentType).length != 0) {
      // window.location.reload();
      if (tempPaymentType.selectedPaymentMethod.paymentType == "VA") {
        navigate(`/${params.id}/bank`);
      } else if (
        tempPaymentType.selectedPaymentMethod.paymentType == "BALANCE"
      ) {
        navigate(`/${params.id}/balance`);
      } else if (tempPaymentType.selectedPaymentMethod.paymentType == "QRIS") {
        navigate(`/${params.id}/qris`);
      }
    }
  };
  const radioBalance = (el: any) => {
    if (el == "LINK_AJA" && balanceLinkAja == "Akun Anda Belum terhubung")
      return true;
    if (el == "BANK_DKI" && balanceDKI == "Akun Anda Belum terhubung")
      return true;
    if (el == "BANK_DKI" && balanceDKI < company.amount) return true;
    if (el == "CLOSEPAY" && balance < company.amount) return true;
    if (el == "CREDIT" && balanceCredit < company.amount) return true;
  };

  useEffect(() => {
    getCheckout();
    if (paymentType == undefined) {
      dispatch(setPaymentTypeRedux({}));
    } else {
      renderPage();
    }
    if (getToken) {
      dispatch(setToken(getToken));
      if (getSuccessUrl && getBackRedirectUrl) {
        navigate(
          `/${params.id}?successRedirectUrl=${getSuccessUrl}&backRedirectUrl=${getBackRedirectUrl}`
        );
      } else {
        navigate(`/${params.id}`);
      }
    }
    if (getSuccessUrl) {
      localStorage.setItem("customSuccessUrl", JSON.stringify(getSuccessUrl));
    } else {
      localStorage.removeItem("customSuccessUrl");
    }
    if (getBackRedirectUrl) {
      localStorage.setItem("customBackUrl", JSON.stringify(getBackRedirectUrl));
    } else {
      localStorage.removeItem("customBackUrl");
    }
    if (getBaseUrl) {
      localStorage.setItem("customBaseUrl", JSON.stringify(getBaseUrl));
    } else {
      localStorage.removeItem("customBaseUrl");
    }
    if (token) checkToken();
  }, [paymentType]);

  useEffect(() => {
    if (company) {
      var date = moment.utc(company.expiredAt).format("YYYY-MM-DD HH:mm:ss");

      var stillUtc = moment.utc(date).toDate();
      var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");

      var timeArr = moment(local).format("x");
      let expired = parseInt(timeArr);
      let dateTimer = expired - Date.now();
      setTimer(Date.now() + dateTimer);
      if (company.status === "REFUNDED") {
        setShowNotFound(true);
        setShowPayment(true);
        setShowPaid(true);
        setShowExpired(true);
        setErrorPage(true);
        setRefunded(false);
      } else if (Date.now() >= expired && refunded) {
        setShowNotFound(true);
        setShowPayment(true);
        setShowPaid(true);
        setShowExpired(false);
        setErrorPage(true);
        setRefunded(true);
      } else if (company.isExpired == true && refunded) {
        setShowNotFound(true);
        setShowPayment(true);
        setShowPaid(true);
        setShowExpired(false);
        setErrorPage(true);
        setRefunded(true);
      } else if (company.status === "CLOSE") {
        setShowNotFound(true);
        setShowPayment(true);
        setShowPaid(false);
        setShowExpired(true);
        setErrorPage(true);
        setRefunded(true);
      } else {
        setShowNotFound(true);
        setShowPayment(false);
        setShowPaid(true);
        setShowExpired(true);
        setErrorPage(true);
        setRefunded(true);
      }
      if (company?.status == "OPEN") {
        const loop = setInterval(async () => {
          getCheckout(false);
        }, 8000);
        return () => clearInterval(loop);
      }
    }
  }, [company]);

  return (
    <>
      <Navbar link={company?.backRedirectUrl} />
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none backdrop-blur-sm focus:outline-none"
        hidden={alert}
      >
        <div className="animate-top-xs relative mx-auto max-w-sm w-8/12">
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none rounded-xl pt-4">
            {/*body*/}
            <div className="relative flex justify-center px-4">
              <h1 className=" text-sm leading-relaxed text-center">
                {message}
              </h1>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end rounded-b mt-3 pb-2">
              <button
                className="bg-[#26A69A] px-6 mx-auto rounded-lg text-white active:bg-emerald-600 font-bold uppercase text-sm py-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={goToLogin}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <div hidden={showErrorPage}>
        <ErrorPage />
      </div>
      <div hidden={showNotFound}>
        <NotFoundCO />
      </div>
      <div hidden={showPaid}>
        <Paid show={showPaid} />
      </div>
      <div hidden={showExpired}>
        <Expired />
      </div>{" "}
      <div hidden={refunded}>
        <Refunded />
      </div>
      {token && user ? (
        <InputPin
          input={pin}
          setInput={setPin}
          show={showPin}
          setShow={setShowPin}
          submit={submitDKI}
          width={60}
          height={63}
          margin="0 0.5rem"
          header="Masukan Password"
          max={max}
          setMax={setMax}
          message={[
            <p className="md:text-2xl text-sm text-black md:mb-4">
              Jika Password benar kode OTP akan dikirimkan ke
              <span className="font-bold"> {user.email} </span>
            </p>,
          ]}
        />
      ) : null}
      <div
        className="p-4 md:w-[50%] 2xl:w-[40%] h-full font-montserrat mx-auto text-sm md:text-sm 2xl:text-lg"
        hidden={showPayment}
      >
        <div className="animate-top">
          <Description />
          <div className="flex flex-col items-center mt-14 space-y-6">
            <div className="">
              <span className="mr-2">{t("home.payBefore")}</span>
              <span className="text-[#FF9723]">{expired}</span>
            </div>
            <Countdown key={timer} date={timer} renderer={renderer} />
            <div
              className="w-full md:w-2/5 py-2 font-bold hover:scale-110 duration-300 text-center text-[#26A69A] bg-[#26A69A] bg-opacity-20 text-xl"
              style={{
                color: color.color1,
                backgroundColor: hexToRgba(color.color1, 0.1),
              }}
            >
              {formatCurrency(data?.amount)}
            </div>
          </div>
          <form className="mt-10 space-y-6 pb-20" onSubmit={submit}>
            <div className="font-medium">{t("home.methode")}</div>
            {balanceOptions.length > 0 ? (
              <div>
                <div
                  className=" bg-[#FFFFFF] rounded-[5px]"
                  style={{ boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.05)" }}
                >
                  <div
                    className="grid grid-cols-3 gap-4 h-[3.25rem] px-3 cursor-pointer hover:text-emerald-400"
                    onClick={() => setHideBalance(!HideBalance)}
                  >
                    <div className="col-span-2 flex items-center space-x-4 ">
                      {iconBalance(color.color1)}
                      <div className=" hover:text-emerald-400">
                        {t("home.balance")}
                      </div>
                    </div>
                    <div className="flex justify-end items-center space-x-4">
                      <div
                        className="radio-modal flex justify-end items-center"
                        hidden={token}
                      >
                        <button
                          className="px-3 py-1 rounded-lg border-1 border-[#26A69A] text-[#26A69A] hover:bg-sky-400 hover:text-white"
                          style={{
                            borderColor: color.color1,
                            color: color.color1,
                          }}
                          onClick={goToLogin}
                        >
                          Login
                        </button>
                      </div>
                      <img
                        src={!HideBalance ? Down : Up}
                        alt=""
                        className="hover:text-emerald-400 cursor-pointer"
                      />
                    </div>
                  </div>
                  <Collapse open={HideBalance} timeout="auto" unmountOnExit>
                    {balanceOptions.map((el: any, index: any) => {
                      return (
                        <div key={index}>
                          <div className="grid grid-cols-3 gap-4 cursor-pointer text-sm md:text-base h-[3.25rem] px-3 border-t border-slate-200 rounded-b-xl">
                            <div className="col-span-2 flex items-center space-x-4">
                              <img
                                src={`${iconPayment(el)}`}
                                alt=""
                                className="w-8"
                              />
                              <div className="flex flex-col">
                                <label
                                  className="font-medium hover:text-emerald-400 cursor-pointer"
                                  htmlFor={el}
                                >
                                  {el === "CLOSEPAY"
                                    ? t("home.mainBalance")
                                    : el.replaceAll("_", " ")}
                                </label>
                                {getBalanceRender(
                                  el,
                                  token,
                                  balance,
                                  balanceLinkAja,
                                  balanceDKI,
                                  balanceCredit
                                )}
                              </div>
                            </div>

                            <div
                              className="radio-modal flex justify-end items-center"
                              hidden={!token}
                            >
                              <div hidden={balanceNoHideDesc}>
                                <input
                                  type="radio"
                                  onClick={balanceDelete}
                                  hidden={radioBalance(el)}
                                  id={el}
                                  name="default-radio"
                                  style={
                                    { "--dynamic-color": color.color1 } as any
                                  }
                                  value={el}
                                  onChange={(event) => {
                                    setMethodBalance(event.target.value.trim());
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Collapse>
                </div>
              </div>
            ) : null}
            {vaOptions.length > 0 ? (
              <div>
                <div
                  className=" bg-[#FFFFFF] rounded-[5px]"
                  style={{ boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.05)" }}
                >
                  <div
                    className="grid grid-cols-3 gap-4 px-3 h-[3.25rem] cursor-pointer hover:text-emerald-400"
                    onClick={() => setHideBanks(!HideBanks)}
                  >
                    <div className="col-span-2 flex items-center space-x-4">
                      {iconBank(color.color1)}
                      <div className=" hover:text-emerald-400">
                        {t("home.bankTransfer")}
                      </div>
                    </div>
                    <div className="flex justify-end items-center">
                      <img
                        src={!HideBanks ? Down : Up}
                        alt=""
                        className="hover:text-emerald-400 cursor-pointer"
                      />
                    </div>
                  </div>
                  <Collapse open={HideBanks} timeout="auto" unmountOnExit>
                    {vaOptions.map((el: any, index: any) => {
                      return (
                        <div key={index}>
                          <div className="flex justify-between bg-[#FFFFFF] h-[3.25rem] text-sm md:text-base px-3 cursor-pointer border-t border-slate-200 rounded-b-xl">
                            <div className="col-span-2 flex items-center space-x-4">
                              <img
                                src={`${iconPayment(el)}`}
                                alt=""
                                className="w-8"
                              />
                              <div className="flex flex-col">
                                <label
                                  className="font-medium  hover:text-emerald-400 cursor-pointer"
                                  htmlFor={el}
                                >
                                  {el.replaceAll("_", " ")}
                                </label>
                              </div>
                            </div>
                            <div className="radio-modal flex justify-end items-center">
                              <input
                                type="radio"
                                onClick={bankDelete}
                                id={el}
                                name="default-radio"
                                style={
                                  { "--dynamic-color": color.color1 } as any
                                }
                                value={el}
                                onChange={(event) => {
                                  setMethodBank(event.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Collapse>
                </div>
              </div>
            ) : null}
            {qrisOptions.length > 0 ? (
              <div>
                <div
                  className=" bg-[#FFFFFF] rounded-[5px]"
                  style={{ boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.05)" }}
                >
                  <div
                    className="grid grid-cols-3 gap-4 h-[3.25rem] px-3 cursor-pointer hover:text-emerald-400"
                    onClick={() => setHideQris(!HideQris)}
                  >
                    <div className="col-span-2 flex items-center space-x-4 cursor-pointer">
                      {iconQris(color.color1)}
                      <div className=" hover:text-emerald-400">Qris</div>
                    </div>
                    <div className="flex justify-end items-center">
                      <img
                        src={!HideQris ? Down : Up}
                        alt=""
                        className="hover:text-emerald-400 cursor-pointer"
                      />
                    </div>
                  </div>
                  <Collapse open={HideQris} timeout="auto" unmountOnExit>
                    {qrisOptions.map((qr: any, index: any) => {
                      return (
                        <div key={index}>
                          <div className="flex justify-between bg-[#FFFFFF] p-3 h-[3.25rem] cursor-pointer border-t border-slate-200 rounded-b-xl">
                            <div className="col-span-2 flex items-center space-x-4">
                              <div className="flex flex-col">
                                <label
                                  className="font-medium hover:text-emerald-400 cursor-pointer"
                                  htmlFor={qr}
                                >
                                  {qr[0].replaceAll(
                                    qr[0],
                                    t("home.qrWithQris")
                                  )}
                                </label>
                              </div>
                            </div>
                            <div className="radio-modal flex justify-end items-center">
                              <input
                                type="radio"
                                onClick={qrisDelete}
                                id={qr}
                                name="default-radio"
                                style={
                                  { "--dynamic-color": color.color1 } as any
                                }
                                value={qr}
                                onChange={(event) => {
                                  setMethodQris(event.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Collapse>
                </div>
              </div>
            ) : null}

            <div>
              <div className="w-full mt-20 space-x-2 py-2 pb-4 mx-auto">
                <div className="flex justify-center">
                  <button
                    disabled={methodPayment()}
                    className="
                     flex items-center justify-center w-full md:h-[40px] h-full px-3 py-2 rounded-lg text-white  disabled:bg-gray-300 active:bg-emerald-600"
                    type="submit"
                    style={{
                      backgroundColor: methodPayment()
                        ? "9CA3AF"
                        : color.color1,
                    }}
                    onClick={() =>
                      localStorage.setItem("choose_payment", true as any)
                    }
                  >
                    {t("home.buttonPay")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Payment;
