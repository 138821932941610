import Config from "../../config/Config";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
import { cekToken } from "./api";
const axios = require("axios");

const baseUrlDev = Config.BaseUrlDev;

export const getBalance = (data) => {
  let url = `${baseUrlDev}mob_merchant/get_saldo_utama`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getTransactions = (data) => {
  let url = `${baseUrlDev}mob_merchant/transaction/summary?startDate=${data.startDate}&endDate=${data.endDate}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getTransactionsAnalytics = (startDate, endDate) => {
  let url = `${baseUrlDev}mob_merchant/transaction/analytics?startDate=${startDate}&endDate=${endDate}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// export const getTransactions = (data) => {
//   let url = `${baseUrlDev}mob_user/get_data_transaksi/summary?${createQueryUrl(
//     getEntity(createArrayEntityFromObject(data), data),
//     "&"
//   )}`;
//   return new Promise((resolve, reject) => {
//     axios
//       .get(url, {
//         headers: {
//           Authorization: `Bearer ${localStorage
//             .getItem("token_redux")
//             .replaceAll('"', "")}`,
//         },
//       })
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const getTransactionsByType = (query, transaction_type) => {
  let url = `${baseUrlDev}mob_user/get_data_transaksi/detail/${transaction_type}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadPDF = (transaction_type, id, query) => {
  let url = `${baseUrlDev}mob_user/get_data_transaksi/pdf/${transaction_type}/${id}?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Content-Type": "application/octet-stream",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadPDFPencairanDana = (id) => {
  let url = `${baseUrlDev}api_default/finance_admin/unduh_pdf_pencairan_dana?idTransaksi=${id}`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_redux")
              .replaceAll('"', "")}`,
            "Content-Type": "application/octet-stream",
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
