import Config from "../../config/Config";
const axios = require("axios");

const baseUrlDev = Config.BaseUrl;

export const getCheckout = (item) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/guest/checkout/${item}`, {
        // headers: {
        //   Authorization: `Bearer ${localStorage
        //     .getItem("token_co_redux")
        //     .replaceAll('"', "")}`,
        // },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getCheckoutToken = (item) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}balance/guest/checkout/${item}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_co_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const paymentType = (id, data,userType) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}balance/guest/checkout/${id}/${userType}`, data, {
        // headers: {
        //   Authorization: `Bearer ${localStorage
        //     .getItem("token_co_redux")
        //     .replaceAll('"', "")}`,
        // },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const paymentTypeToken = (id, data,userType) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrlDev}balance/guest/checkout/${id}/${userType}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_co_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getPin = (data,userType) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}authentication/${userType}/security-code/validation`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_co_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const Payment = (item, data,userType) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrlDev}balance/transaction/${userType}/payment/inquiry_close/${item.id}`,
        data,
        {
          headers: {
            ReAuthenticated: item.securityCodeToken,
            Authorization: `Bearer ${localStorage
              .getItem("token_co_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const PaymentDKI = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrlDev}balance/member/linked_account/bank_dki/payment_checkout`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_co_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const linkAjaSuccess = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrlDev}payment/link-aja/linked-account/payment/callback/SUCCESS?trxId=${data.trxId}&linkAjaTrxId=${data.linkAja}&refNum=${data.refNum}`,
        {
          headers: {
            // Authorization: `Bearer ${localStorage
            //   .getItem("token_co_redux")
            //   .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPinConfig = (userType) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrlDev}user/account/${userType}/has_security_code`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_co_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
