import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PaymentType from "../../models/PaymentType";
import { saveState, loadState, removeState } from "../browser-storage/browser-storage";

type InitialState = {
    paymentType:  PaymentType
}
const type = 'paymentType'

const initialState: InitialState = {
    paymentType: loadState(type)
}

export const paymentTypeSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setPaymentTypeRedux: (state, action: PayloadAction<PaymentType>) => {
      state.paymentType = action.payload;
      saveState(type, action.payload)
    },
    removePaymentType: (state) => {
      state.paymentType = {};
      removeState(type)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPaymentTypeRedux, removePaymentType } =  paymentTypeSlice.actions;

export default  paymentTypeSlice.reducer;
