import React, { Component, useEffect, useState } from "react";

import {} from "../core/feature/store";

import { useAppSelector, useAppDispatch } from "../core/feature/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import * as API_PAYMENT from "../core/service/api_payment.js";

import paid from "../assets/images/icon_checkout/paid.svg";

import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import Company from "../core/models/company";
import { setLoading } from "../core/feature/config/configSlice";
import { removeUser, setUser } from "../core/feature/user/userSlice";
import { removeToken } from "../core/feature/token/tokenSlice";
import { removePaymentType } from "../core/feature/PaymentType/PaymentType";
import User from "../core/models/user";
import PaymentType from "../core/models/PaymentType";
import { setPaymentTypeRedux } from "../core/feature/PaymentType/PaymentType";
import { formatCurrency, humanizedText } from "../config/global";
import moment from "moment";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const LinkAjaBalance = () => {
  const paymentType: any = useAppSelector(
    (state) => state.paymentType.paymentType
  );
  // const user: User = useAppSelector((state) => state.user.user);
  const token: any = useAppSelector((state) => state.token.token);
  // const balanceCode: any = useAppSelector(
  //   (state) => state.balanceCode.balanceCode
  // );
  // const [balance, setbalance] = useState(
  //   useAppSelector((state) => state.balance.balance)
  // );
  // let location: any = useLocation();
  const [id, setId] = useState<any>("");
  const [refNum, setRefNum] = useState<any>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(true);
  const params = useParams();

  const title = "Pembayaran";
  document.title = title;
  const cancel = () => {
    setAlert(false);
    setMessage("Apakah Anda Yakin Mengubah Metode Pembayaran ? ");
  };
  const actionCancel = () => {
    if (token) {
      navigate(`/${params.id}?access_token=${token}`);
      dispatch(setPaymentTypeRedux({}));
    } else {
      navigate(`/${params.id}`);
      dispatch(setPaymentTypeRedux({}));
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("pgpToken")) return actionCancel();

    setId(localStorage.getItem("pgpToken") as string);
    setRefNum(paymentType?.requiredPaymentData.refNum);
    if (id && refNum) return document.getElementById("btn")?.click();

    window.scrollTo(0, 0);
  }, [id, refNum]);
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none backdrop-blur-sm focus:outline-none"
        hidden={alert}
      >
        <div className="animate-top relative mx-auto flex justify-center">
          <div className="border-0 shadow-lg relative flex flex-col bg-[#FFFFFF] outline-none focus:outline-none rounded-xl w-4/5 md:w-[500px] 2xl:w-[600px]">
            <div className="relative space-y-4 p-4">
              <div className="text-center text-emerald-400 text-xl md:text-2xl 2xl:text-3xl">
                Konfirmasi
              </div>
              <div className="text-lg md:text-xl 2xl:text-2xl text-center">
                {message}
              </div>
            </div>
            <div className="grid grid-cols-2 rounded-b p-4 space-x-2">
              <div>
                <button
                  className="border-1 border-[#EC1A1A] w-full px-6 mx-auto rounded-lg text-[#EC1A1A] active:bg-[#EC1A1A] active:text-white font-bold uppercase text-sm py-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  md:text-xl 2xl:text-2xl"
                  type="button"
                  onClick={() => setAlert(true)}
                >
                  Batal
                </button>
              </div>
              <div>
                <button
                  style={{
                    backgroundColor: "#0AB185",
                  }}
                  className="w-full px-6 mx-auto rounded-lg text-white active:bg-emerald-600 font-bold uppercase text-sm py-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  md:text-xl 2xl:text-2xl"
                  type="button"
                  onClick={actionCancel}
                >
                  Ubah
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navbar link="" />
      <div className="w-full h-screen flex align-items-center justify-center">
        <div className="p-4 md:w-[80%] lg:w-[70%] xl:w-[60%] 2xl:w-2/4 h-full mt-20">
          <form
            action={paymentType?.requiredPaymentData.url}
            method="POST"
            className="space-y-4 mt-4 p-3"
          >
            <div className="text-center">
              <textarea
                hidden
                id="pgpToken"
                name="pgpToken"
                placeholder=""
                onChange={id}
                value={id.replaceAll('"', "")}
              ></textarea>
              <br />
              <br />
            </div>
            <div className="text-center">
              <input
                hidden
                name="refNum"
                placeholder=""
                value={refNum}
                onChange={refNum}
              ></input>
              <br />
            </div>
            <div className="text-center">
              <button
                hidden
                style={{
                  backgroundColor: "#0AB185",
                }}
                className="px-6 mx-auto rounded-lg text-white active:bg-emerald-600 font-bold uppercase text-xl md:text-xl 2xl:text-xl py-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="submit"
                onClick={() => {
                  localStorage.removeItem("pgpToken");
                }}
                id="btn"
              >
                Ok
              </button>
            </div>
          </form>

          <div className="flex justify-center md:text-xl 2xl:text-2xl">
            <button
              hidden
              className="bg-[#FFFFFF] w-full h-[40px] md:h-[50px] 2xl:h-[60px] rounded-lg border-1 border-[#9A9999] text-[#9A9999] active:bg-slate-200 active:text-[#FFFFFF]"
              onClick={cancel}
            >
              Ubah Metode Pembayaran
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkAjaBalance;
