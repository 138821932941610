import { faInfoCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToastConfig: any = {
  warning: {
    // Custom Icon
    icon: (
      <FontAwesomeIcon
        style={{ color: "F1C40F" }}
        icon={faWarning}
      ></FontAwesomeIcon>
    ),
  },

  info: {
    // Custom Icon
    icon: (
      <FontAwesomeIcon
        style={{ color: "3498DB" }}
        icon={faInfoCircle}
      ></FontAwesomeIcon>
    ),
  },
};

export default ToastConfig;
