
import Payment from "./pages/Payment";
import PaymentBank from "./pages/PaymentBank";
import PaymentBalance from "./pages/PaymentBalance";
import PaymentQris from "./pages/PaymentQris";
import NotFound from "./pages/NotFound";
import linkAjaBalance from "./pages/linkAjaBalance";

const Routes: any = [
  ,
  {
    path: `/`,
    component: NotFound,
  },
  {
    path: `/:id`,
    component: Payment,
  },
  {
    path: `/:id/:accesToken`,
    component: Payment,
  },
  {
    path: `/:id/bank`,
    component: PaymentBank,
  },
  {
    path: `/:id/balance`,
    component: PaymentBalance,
  },
  {
    path: `/:id/qris`,
    component: PaymentQris,
  },
  {
    path: `/:id/balance/link-aja`,
    component: linkAjaBalance,
  },
];

export default Routes;
