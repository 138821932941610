import { useState } from "react";
import { useTranslation } from "react-i18next";
import Edit from "../assets/images/icon_checkout/edit.svg";
import Collapse from "../config/Collapse";
import { ConfigColor } from "../config/global";
import { useAppSelector } from "../core/feature/hooks";

const Description = () => {
  const { t } = useTranslation();
  const color = ConfigColor();
  const company: any = useAppSelector((state) => state.company.company);
  const [show, setShow] = useState(true);

  return (
    <div className="w-full border border-slate-400 grid grid-cols-4 gap-4 p-4 2xl:h-[100px] rounded-xl bg-white">
      <div className="col-span-3 flex space-x-2 items-start">
        <img src={Edit} alt="" className="" />
        <div className="flex flex-col">
          <span> {t("home.description")}</span>
          <span className="text-slate-400">{company?.description}</span>
          <Collapse open={show} timeout="auto" unmountOnExit>
            <span className="text-slate-400">
              Reference ID Kit {company?.transactionId}
            </span>
          </Collapse>
        </div>
      </div>
      <div
        className="flex justify-end hover:scale-105 duration-300 cursor-pointer"
        onClick={() => setShow(!show)}
        style={{ color: color.color1 }}
      >
        {show ? "Hide" : "Unhide"}
      </div>
    </div>
  );
};

export default Description;
