import { useAppSelector, useAppDispatch } from "../core/feature/hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import LogoClosepay from "../assets/images/closepayLogo.svg";
import Back from "../assets/images/icon_checkout/back.svg";
import Config from "../config/Config";
import { ConfigColor } from "../config/global";

interface EmployeeProps {
  link: any;
}

export default function Navbar({ link }: EmployeeProps) {
  const color = ConfigColor();
  const company: any = useAppSelector((state) => state.company.company);
  const url =
  company?.userType == "MEMBER"
    ? Config.BaseUrlRedirectMember
    : Config.BaseUrlRedirectMerchant;
  let navigate = useNavigate();
  let logo;
  const customSuccesUrl = JSON.parse(
    localStorage.getItem("customSuccessUrl") as any
  );
  const customBackUrl = JSON.parse(
    localStorage.getItem("customBackUrl") as any
  );

  const navigateTo = () => {
    if (customBackUrl) {
      window.location.href = `https://${customBackUrl}`;
    } else {
      window.location.href = `${link}`;
    }
    localStorage.removeItem("customSuccessUrl");
    localStorage.removeItem("customBackUrl");
    localStorage.removeItem("customBaseUrl");
  };

  if (company) {
    if (company?.logo == "string" || null || undefined) {
      logo = LogoClosepay;
    } else {
      logo = company.logo;
    }
  }

  const navigateToHome = () => {
    if (customBackUrl) {
      window.location.href = `https://${customBackUrl}`;
    } else {
      // window.location.href = `${url}/${company?.company?.initial}`;
      window.location.href = `${link}`;
    }
    localStorage.removeItem("customSuccesUrl");
    localStorage.removeItem("customBackUrl");
    localStorage.removeItem("customBaseUrl");
  };

  return (
    <div id="header-co">
      <div
        className="md:flex items-center top-0 right-0 left-0 justify-between m-0 py-2 px-6 z-50 w-full hidden"
        style={{ backgroundColor: color.color1 }}
      >
        <div className="hidden md:flex 2xl:flex md:w-3/4 2xl:2/5 mx-auto">
          <img
            src={logo}
            className="h-14 cursor-pointer"
            onClick={navigateToHome}
          />
        </div>
      </div>
      <div className="md:hidden items-center top-0 right-0 left-0 justify-between m-0 py-2 px-6 z-50 w-full ">
        <div className="md:hidden grid grid-cols-3 gap-3 w-full mt-10 items-center">
          <div className="cursor-pointer">
            <div className="" onClick={navigateTo}>
              <img src={Back} alt="" />
            </div>
          </div>
          <div className="flex justify-center items-center">
            <h2 className="md:text-2xl 2xl:text-3xl font-bold text-base">
              Checkout
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
