import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BalanceCode from "../../models/BalanceCode";
import { saveState, loadState, removeState } from "../browser-storage/browser-storage";

type InitialState = {
    balanceCode:  BalanceCode
}
const type = 'balanceCode'

const initialState: InitialState = {
    balanceCode: loadState(type)
}

export const balanceCodeSlice = createSlice({
  name: type,
  initialState: initialState,
  reducers: {
    setBalanceCode: (state, action: PayloadAction<BalanceCode>) => {
      state.balanceCode = action.payload;
      saveState(type, action.payload)
    },
    removeBalanceCode: (state) => {
      state.balanceCode = {};
      removeState(type)
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBalanceCode, removeBalanceCode } =  balanceCodeSlice.actions;

export default  balanceCodeSlice.reducer;
