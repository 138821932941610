import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Copy from "../assets/images/icon_checkout/copy.svg";
import Navbar from "../components/Navbar";
import { useAppDispatch, useAppSelector } from "../core/feature/hooks";
import { } from "../core/feature/store";

import { useNavigate, useParams } from "react-router-dom";
import Down from "../assets/images/icon_checkout/Down.svg";
import Up from "../assets/images/icon_checkout/Up.png";

import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CancelComponent from "../components/CancelComponent";
import Description from "../components/Description";
import Footer from "../components/Footer";
import resp from "../config/JsonDetail";
import { ConfigColor, formatCurrency, iconPayment } from "../config/global";
import respEn from "../config/jsonDetailEn";
import { setPaymentTypeRedux } from "../core/feature/PaymentType/PaymentType";
import { setLoading } from "../core/feature/config/configSlice";
import User from "../core/models/user";
import * as API_PAYMENT from "../core/service/api_payment.js";

const PaymentBank = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const color = ConfigColor();
  const user: User = useAppSelector((state) => state.user.user);
  const token: String = useAppSelector((state) => state.token.token);
  const company: any = useAppSelector((state) => state.company.company);
  const paymentType: any = useAppSelector(
    (state) => state.paymentType.paymentType
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(true);
  const [dateNow, setDateNow] = useState<any>();
  const [instruction, setInstruction] = useState<any>(
    i18n.language === "en"
      ? paymentType?.selectedPaymentMethod?.provider?.includes("BSI")
        ? respEn.instructions_va_BSI
        : respEn.instructions_va
      : paymentType?.selectedPaymentMethod?.provider?.includes("BSI")
      ? resp.instructions_va_BSI
      : resp.instructions_va
  );
  const [VAId, setVAId] = useState("");
  const customSuccesUrl = JSON.parse(
    localStorage.getItem("customSuccessUrl") as any
  );
  const customBackUrl = JSON.parse(
    localStorage.getItem("customBackUrl") as any
  );

  let arrow = ">";

  const title = "Pembayaran";
  document.title = title;

  const cancel = () => {
    setAlert(false);
    setMessage(t("modalCancel.about"));
  };
  
  const actionCancel = () => {
    dispatch(setPaymentTypeRedux({}));
    localStorage.setItem("choose_payment", false as any);
    if (token) {
      if (customSuccesUrl && customBackUrl) {
        navigate(
          `/${params.id}?access_token=${token}&successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
        );
        dispatch(setPaymentTypeRedux({}));
      } else {
        navigate(`/${params.id}?access_token=${token}`);
        dispatch(setPaymentTypeRedux({}));
      }
    } else {
      if (customSuccesUrl && customBackUrl) {
        navigate(
          `/${params.id}?successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
        );
        dispatch(setPaymentTypeRedux({}));
      } else {
        navigate(`/${params.id}`);
        dispatch(setPaymentTypeRedux({}));
      }
    }
  };
  const copyVa = async (noVA: any) => {
    await navigator.clipboard.writeText(noVA);
    toast.success("Nomor VA disalin");
  };

  const getCheckout = async (showLoader = true) => {
    dispatch(setLoading(showLoader));
    await API_PAYMENT.getCheckout(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        setDateNow(Date.now());
        if (res.data.data.status !== "OPEN") {
          if (customSuccesUrl && customBackUrl) {
            navigate(
              `/${params.id}?successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
            );
            dispatch(setPaymentTypeRedux({}));
          } else {
            navigate(`/${params.id}`);
            dispatch(setPaymentTypeRedux({}));
          }
        } else if (res.data.data.selectedPaymentMethod == null || undefined) {
          if (customSuccesUrl && customBackUrl) {
            navigate(
              `/${params.id}?successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
            );
            dispatch(setPaymentTypeRedux({}));
          } else {
            navigate(`/${params.id}`);
            dispatch(setPaymentTypeRedux({}));
          }
        } else {
          dispatch(setPaymentTypeRedux(res.data.data));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (paymentType?.status == "OPEN") {
      const loop = setInterval(() => {
        getCheckout(false);
      }, 8000);
      return () => clearInterval(loop);
    } else {
      localStorage.setItem("choose_payment", false as any);
      if (customSuccesUrl && customBackUrl) {
        navigate(
          `/${params.id}?successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
        );
        dispatch(setPaymentTypeRedux({}));
      } else {
        navigate(`/${params.id}`);
        dispatch(setPaymentTypeRedux({}));
      }
    }

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  useEffect(() => {
    var date = moment.utc(paymentType?.expiredAt).format("YYYY-MM-DD HH:mm:ss");

    var stillUtc = moment.utc(date).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");

    var timeArr = moment(local).format("x");
    let expired = parseInt(timeArr);

    if (dateNow >= expired) {
      if (token) {
        if (customSuccesUrl && customBackUrl) {
          navigate(
            `/${params.id}?access_token=${token}&successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
          );
          dispatch(setPaymentTypeRedux({}));
        } else {
          navigate(`/${params.id}?access_token=${token}`);
          dispatch(setPaymentTypeRedux({}));
        }
      } else {
        if (customSuccesUrl && customBackUrl) {
          navigate(
            `/${params.id}?successRedirectUrl=${customSuccesUrl}&backRedirectUrl=${customBackUrl}`
          );
          dispatch(setPaymentTypeRedux({}));
        } else {
          navigate(`/${params.id}`);
          dispatch(setPaymentTypeRedux({}));
        }
      }
    }
  }, [dateNow]);

  return (
    <>
      <CancelComponent
        show={alert}
        setShow={setAlert}
        message={message}
        submit={actionCancel}
      />

      <Navbar link={cancel} />
      <div className="p-4 md:w-[50%] 2xl:w-[40%] h-full mx-auto text-sm md:text-lg">
        <div className="animate-top space-y-12">
          <Description />
          <div className="border border-slate-200 rounded-xl flex flex-col bg-white">
            <div className="flex items-center space-x-2 p-2 px-4 border-b border-slate-200 ">
              <img
                src={`${iconPayment(
                  paymentType?.selectedPaymentMethod?.provider
                )}`}
                alt=""
                className="h-12 w-12"
              />
              <div>{paymentType?.selectedPaymentMethod?.provider}</div>
            </div>
            <div className="px-4 py-4 space-y-4">
              <div className="space-y-2 ">
                <span>Virtual Account</span>
                <div className="flex items-center space-x-4">
                  <span
                    style={{
                      color: color.color1,
                    }}
                  >
                    {paymentType?.requiredPaymentData?.virtualAccountNumber}
                  </span>
                  <button
                    onClick={() =>
                      copyVa(
                        paymentType?.requiredPaymentData?.virtualAccountNumber
                      )
                    }
                  >
                    <img src={Copy} alt="" className="w-5 cursor-pointer" />
                  </button>
                </div>
              </div>
              <div className="space-y-2">
                <span>{t("bank.virtualName")}</span>
                <div className="flex items-center space-x-4">
                  <span
                    style={{
                      color: color.color1,
                    }}
                  >
                    {paymentType?.requiredPaymentData?.name}
                  </span>
                </div>
              </div>
              <div className="space-y-2">
                <span>{t("bank.nominalPay")}</span>
                <div className="flex items-center space-x-4">
                  <span
                    style={{
                      color: color.color1,
                    }}
                  >
                    {formatCurrency(paymentType?.requiredPaymentData?.amount)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="space-y-4">
              <div className="text-sm md:text-lg ">
                <span>{t("global.paymentInstructions")}</span>
              </div>
              <div className="text-sm md:text-lg space-y-4">
                {instruction.map((el: any, index: any) => {
                  return (
                    <div className="border border-slate-200 rounded-xl bg-white">
                      <div
                        className="grid grid-cols-4 gap-4 cursor-pointer hover:text-[#26A69A]"
                        onClick={() => {
                          if (el.id === VAId) {
                            setVAId("");
                          } else {
                            setVAId(el.id);
                          }
                        }}
                      >
                        <div className="col-span-3 flex items-center space-x-4 h-[35px] md:h-[50px] lg:h-[50px] xl:h-[50px] 2xl:h-[60px] px-4">
                          <div className="text-xs md:text-lg  ">{el.title}</div>
                        </div>
                        <div className="flex justify-end items-center pr-4 cursor-pointer">
                          <img src={VAId === el.id ? Up : Down} alt="" />
                        </div>
                      </div>
                      {_.find(el.intruction, function (o) {
                        return o.idlevel1 === VAId;
                      }) &&
                        VAId && (
                          <div className="bg-[#F4F6F8] px-2 py-3 text-slate-400 text-xs space-y-2">
                            <div className="flex items-center space-x-4">
                              <div className="md:text-lg  border-dashed border-b border-slate-400 py-2 w-full">
                                <span>
                                  <b>{t("global.Instructions")}</b>
                                </span>
                              </div>
                            </div>
                            {el.intruction.map((item: any, index: any) => {
                              return (
                                <div className="flex items-center space-x-4">
                                  <div
                                    className="rounded-full  text-[#FFFFFF] p-2 md:text-lg w-8 h-8 flex items-center justify-center"
                                    style={{
                                      backgroundColor: color.color1,
                                    }}
                                  >
                                    {item.id}
                                  </div>
                                  <div className="md:text-lg  border-b border-slate-400 py-2 w-full">
                                    {item?.title
                                      .replaceAll(
                                        "{get_VA}",
                                        paymentType?.requiredPaymentData
                                          ?.virtualAccountNumber
                                      )
                                      .replaceAll(
                                        "{get_VA_BSI}",
                                        paymentType?.requiredPaymentData.virtualAccountNumber?.slice(
                                          4
                                        )
                                      )
                                      .replaceAll(
                                        "{kode_institusi}",
                                        paymentType?.requiredPaymentData.virtualAccountNumber?.substring(
                                          0,
                                          4
                                        )
                                      )
                                      .replaceAll(
                                        "BRI",
                                        paymentType?.selectedPaymentMethod
                                          ?.provider
                                      )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className=" w-full mt-14 space-y-4 py-2 pb-4 mx-auto">
            <div className="flex justify-center">
              <button
                className="bg-[#FFFFFF] w-full h-[40px] md:h-[40px] rounded-lg border-1 border-[#9A9999] text-[#9A9999] active:bg-slate-200 active:text-[#FFFFFF]"
                onClick={cancel}
              >
                {t("global.buttonCancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentBank;
