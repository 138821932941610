import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./assets/style/custom.css";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Routing from "./Routes";
import "bootstrap/dist/css/bootstrap.css";
import "./config/font.css";
import LoadingOverlay from "react-loading-overlay-ts";
import { useAppSelector } from "./core/feature/hooks";
import { ToastContainer } from "react-toastify";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import Company from "../src/core/models/company";
import closepayLogo from "./assets/images/single-logo-closepay.png";

import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import User from "./core/models/user";
const doc = new jsPDF();

function App() {
  const { i18n } = useTranslation();
  let isLoading: boolean = useAppSelector((state) => state.config.loading);
  const company: Company = useAppSelector((state) => state.company.company);
  const user: User = useAppSelector((state) => state.user.user);
  const [title, setTitle] = useState("Closepay");
  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  useEffect(() => {
    // setTitle(company?.name);
    // if(paymentType=="BALANCE"){
    //   Navigate(``)
    // }
    i18n.changeLanguage(user?.lang || "id");
    const favicon: any | null = getFaviconEl();

    favicon.href = company?.logo != null ? company?.logo : closepayLogo;
    let companyName = company?.name as string;
    document.title = companyName != null ? companyName : "Tanpa Judul";
  }, [company,user]);
  return (
    <div className="App-full bg-mobile font-montserrat">
      <ToastContainer></ToastContainer>
      <Toaster></Toaster>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <Routes>
          {Routing.map((prop: any, index: any) => {
            if (prop.path == "") {
              return (
                <Route
                  key={`menu-${index}`}
                  path="/"
                  element={<Navigate to={prop.redirect} replace />}
                />
              );
            } else {
              return (
                <Route
                  key={"menu-" + index}
                  path={prop.path}
                  element={<prop.component />}
                />
              );
            }
          })}
        </Routes>
      </LoadingOverlay>
    </div>
  );
}

export default App;
