import Config from "../../config/Config";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
  getEntityPy,
} from "../../config/global";
const axios = require("axios");

const baseUrlDev = Config.BaseUrlDev;

export const getProduct = (query) => {
  let url = `${baseUrlDev}commerce/member/product?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllProducts = (query) => {
  let url = `${baseUrlDev}commerce/member/product${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProductPub = (companyId) => {
  let url = `${baseUrlDev}commerce/member/product/pub/${companyId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProductByProductIds = (productIds) => {
  let url = `${baseUrlDev}commerce/member/product/byProductIds?productIds=${productIds.join(
    ","
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProductDetail = (id) => {
  let url = `${baseUrlDev}commerce/member/product/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCategory = (query) => {
  let url = `${baseUrlDev}commerce/category?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCategoryByID = (id) => {
  let url = `${baseUrlDev}commerce/category/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComboCategory = (query) => {
  let url = `${baseUrlDev}commerce/combo/category?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getWishlist = () => {
  let url = `${baseUrlDev}commerce/member/wishlist`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateWishlist = (productId) => {
  let url = `${baseUrlDev}commerce/member/wishlist`;
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${url}`,
        { productId },
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateConfirmedOrder = (transactionId, data) => {
  let url = `${baseUrlDev}commerce/member/order/${transactionId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCartList = (query) => {
  let url = `${baseUrlDev}commerce/member/cart?${createQueryUrl(getEntityPy(createArrayEntityFromObject(query), query), "&")}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addToCart = (productId, quantity) => {
  let url = `${baseUrlDev}commerce/member/cart`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${url}`,
        { productId, quantity },
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const addToCartList = (data) => {
  let url = `${baseUrlDev}commerce/member/cart/list`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCart = (productId, deleted, quantity) => {
  let url = `${baseUrlDev}commerce/member/cart`;
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${url}/${productId}`,
        { deleted, quantity },
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteCart = (cartIds) => {
  let url = `${baseUrlDev}commerce/member/cart/delete`;
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${url}`,
        { cartIds },
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getOrderList = (query) => {
  let url = `${baseUrlDev}commerce/member/order?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getOrderById = (id) => {
  let url = `${baseUrlDev}commerce/member/order/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAddress = (query) => {
  let url = `${baseUrlDev}commerce/member/address?${createQueryUrl(
    getEntity(createArrayEntityFromObject(query), query),
    "&"
  )}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addAddress = (data) => {
  let url = `${baseUrlDev}commerce/member/address`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateAddress = (addressId, data) => {
  let url = `${baseUrlDev}commerce/member/address/${addressId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCourier = (merchantId) => {
  let url = `${baseUrlDev}commerce/member/ship/merchant/${merchantId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sumaryOrder = (data) => {
  let url = `${baseUrlDev}commerce/member/order/summary`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const executeOrder = (data, token) => {
  let url = `${baseUrlDev}commerce/member/order`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Re-Authentication": token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadPDF = (id) => {
  let url = `${baseUrlDev}commerce/member/pdf/order/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Content-Type": "application/pdf",
        },
        responseType: "blob",
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadPDFMarketplace = (id) => {
  let url = `${baseUrlDev}commerce/member/pdf/order/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Content-Type": "application/pdf",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getListVa = () => {
  let url = `${baseUrlDev}commerce/member/order/listVa`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getListToko = () => {
  let url = `${baseUrlDev}commerce/member/shop`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getProductToko = (cId, mId) => {
  let url = `${baseUrlDev}commerce/member/product/pub/${cId}?merchantId=${mId}`;
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const initMarketplace = () => {
  let url = `${baseUrlDev}commerce/member/save`;
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${url}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage
              .getItem("token_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
