import React, { useEffect } from "react";
import { useAppSelector } from "../core/feature/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import expired from "../assets/images/icon_checkout/expired.svg";
import { ConfigColor } from "../config/global";
import _ from "lodash"

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const Expired = () => {
  const color = ConfigColor();
  const company: any = useAppSelector((state) => state.company.company);
  const customSuccesUrl = JSON.parse(
    localStorage.getItem("customSuccessUrl") as any
  );
  // Karakter yang ingin dicari dalam nilai key
  const charToFind = "https://";

  // Mendapatkan nilai dari key menggunakan _.get
  const value = _.get(company, "successRedirectUrl");

  // Memeriksa apakah karakter tertentu ada dalam nilai key menggunakan _.includes
  const goToBack = () => {
    if (customSuccesUrl) {
      window.location.href = `https://${customSuccesUrl}`;
    } else {
      if (_.includes(value, charToFind)) {
        window.location.href = `${company?.successRedirectUrl}`;
      } else {
        window.location.href = `https://${company?.successRedirectUrl}`;
      }
    }
    localStorage.removeItem("customSuccessUrl");
    localStorage.removeItem("customBackUrl");
    localStorage.removeItem("customBaseUrl");
  };

  const title = "Pembayaran";
  document.title = title;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [customSuccesUrl]);
  return (
    <>
      <div className="w-full md:w-3/4 2xl:2/5 h-full mx-auto">
        <div className="space-y-8">
          <div className="mt-24">
            <img
              src={expired}
              alt=""
              className="mx-auto w-[100%] md:w-[50%] lg:w-[50%] xl:w-[40%] 2xl:w-[40%]"
            />
          </div>
          <div className="md:text-xl 2xl:text-2xl text-center space-y-4">
            <div className="text-[#EC1A1A] mx-auto">Waktu Pembayaran Habis</div>
            <div className="text-sm md:text-lg 2xl:text-xl text-[#263339]">
              Waktu Pembayaran Anda Telah Habis. <br /> Silahkan Chekout Ulang
            </div>
          </div>
          <div className="flex justify-center px-4">
            <button
              className=" w-full text-white p-2 rounded md:w-[30%]"
              onClick={goToBack}
              style={{ backgroundColor: color.color1 }}
            >
              Kembali
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Expired;
