export const iconBank = (color?: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clip-path="url(#clip0_6511_44900)">
        <path
          d="M11.0535 12.5068H9.16016V22.1468H11.0535V12.5068Z"
          stroke="#FF9723"
          stroke-miterlimit="10"
        />
        <path
          d="M15.3738 12.5068H13.4805V22.1468H15.3738V12.5068Z"
          stroke="#FF9723"
          stroke-miterlimit="10"
        />
        <path
          d="M19.7068 12.5068H17.8135V22.1468H19.7068V12.5068Z"
          stroke="#FF9723"
          stroke-miterlimit="10"
        />
        <path
          d="M24.0271 12.5068H22.1338V22.1468H24.0271V12.5068Z"
          stroke="#FF9723"
          stroke-miterlimit="10"
        />
        <path
          d="M27.0664 25.4399H6.34645C5.66161 25.4399 5.10645 25.9951 5.10645 26.6799V27.2399C5.10645 27.9248 5.66161 28.4799 6.34645 28.4799H27.0664C27.7513 28.4799 28.3064 27.9248 28.3064 27.2399V26.6799C28.3064 25.9951 27.7513 25.4399 27.0664 25.4399Z"
          stroke={color}
          stroke-miterlimit="10"
        />
        <path
          d="M24.9602 22.6934H8.4535C7.93067 22.6934 7.50684 23.1172 7.50684 23.64V24.48C7.50684 25.0029 7.93067 25.4267 8.4535 25.4267H24.9602C25.483 25.4267 25.9068 25.0029 25.9068 24.48V23.64C25.9068 23.1172 25.483 22.6934 24.9602 22.6934Z"
          stroke={color}
          stroke-miterlimit="10"
        />
        <path
          d="M25.9596 12.0801H7.2929C7.0861 12.1022 6.87696 12.0831 6.67759 12.0239C6.47822 11.9647 6.29257 11.8665 6.13138 11.7351C5.97019 11.6036 5.83666 11.4416 5.73851 11.2582C5.64036 11.0748 5.57954 10.8738 5.55957 10.6668C5.56839 10.3704 5.66765 10.0839 5.84401 9.84555C6.02037 9.60722 6.26541 9.42853 6.54624 9.33346L15.8796 5.6668C16.3394 5.49388 16.8464 5.49388 17.3062 5.6668L26.6662 9.33346C26.9471 9.42853 27.1921 9.60722 27.3685 9.84555C27.5448 10.0839 27.6441 10.3704 27.6529 10.6668C27.6123 11.0773 27.4121 11.4555 27.0954 11.7199C26.7786 11.9842 26.3708 12.1136 25.9596 12.0801Z"
          stroke={color}
          stroke-miterlimit="10"
        />
        <path
          d="M17.6002 9.99982C17.5266 10.1271 17.4209 10.2329 17.2935 10.3065C17.1524 10.3951 16.9927 10.4499 16.8269 10.4665V10.7998H16.5469V10.4665C16.3114 10.4551 16.0864 10.366 15.9069 10.2132C15.8269 10.1442 15.7637 10.058 15.7221 9.96093C15.6805 9.86387 15.6616 9.75861 15.6669 9.65315H16.1735C16.1843 9.75414 16.221 9.85062 16.2802 9.93315C16.3502 10.0082 16.4449 10.0556 16.5469 10.0665V9.14648C16.3927 9.10751 16.2413 9.05852 16.0935 8.99982C15.9725 8.94729 15.867 8.86467 15.7869 8.75982C15.7022 8.63414 15.6601 8.48456 15.6669 8.33315C15.664 8.22407 15.6839 8.11557 15.7252 8.01458C15.7665 7.91358 15.8284 7.82228 15.9069 7.74648C16.0851 7.58967 16.31 7.49595 16.5469 7.47982V7.14648H16.8269V7.47982C17.0429 7.49594 17.2482 7.57994 17.4135 7.71982C17.4908 7.78886 17.5531 7.87297 17.5967 7.96697C17.6403 8.06098 17.6642 8.16291 17.6669 8.26648H17.1602C17.1589 8.16746 17.1209 8.07244 17.0535 7.99982C16.9939 7.93262 16.9146 7.88596 16.8269 7.86648V8.79982C16.9805 8.83511 17.1319 8.87963 17.2802 8.93315C17.3973 8.98607 17.4985 9.06883 17.5735 9.17315C17.6583 9.29882 17.7003 9.44841 17.6935 9.59982C17.6978 9.739 17.6656 9.87688 17.6002 9.99982ZM16.2669 8.57315C16.3516 8.64758 16.4518 8.70224 16.5602 8.73315V7.87982C16.507 7.88228 16.4548 7.89535 16.4068 7.91824C16.3587 7.94114 16.3157 7.97341 16.2802 8.01315C16.2392 8.04965 16.207 8.09506 16.1862 8.1459C16.1654 8.19675 16.1565 8.25168 16.1602 8.30648C16.1552 8.40417 16.1887 8.49992 16.2535 8.57315H16.2669ZM17.1202 9.90648C17.1574 9.86769 17.1861 9.82156 17.2045 9.77106C17.2228 9.72055 17.2305 9.66677 17.2269 9.61315C17.236 9.55988 17.2296 9.50509 17.2083 9.4554C17.187 9.40572 17.1518 9.36326 17.1069 9.33315C17.0271 9.25895 16.9313 9.20419 16.8269 9.17315V10.0265C16.9303 10.0272 17.0302 9.98914 17.1069 9.91982L17.1202 9.90648Z"
          fill="#FF9723"
        />
      </g>
      <defs>
        <clipPath id="clip0_6511_44900">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const iconBalance = (color?: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clip-path="url(#clip0_6511_44899)">
        <path
          d="M20.8931 7.57324H12.3464C10.7728 7.57324 9.26364 8.19836 8.15092 9.31108C7.0382 10.4238 6.41309 11.933 6.41309 13.5066V20.5999C6.41662 22.1712 7.0433 23.677 8.15563 24.7868C9.26796 25.8966 10.7751 26.5199 12.3464 26.5199H20.8931C22.4632 26.5199 23.9689 25.8962 25.0792 24.786C26.1894 23.6758 26.8131 22.17 26.8131 20.5999V13.5066C26.8148 12.728 26.663 11.9568 26.3663 11.237C26.0696 10.5172 25.6338 9.86302 25.0839 9.31188C24.534 8.76074 23.8808 8.32349 23.1617 8.02515C22.4425 7.72681 21.6716 7.57324 20.8931 7.57324ZM25.3331 18.8399H22.0664C21.5595 18.8364 21.0746 18.6326 20.7174 18.2729C20.3602 17.9132 20.1597 17.4268 20.1598 16.9199C20.1597 16.413 20.3602 15.9266 20.7174 15.567C21.0746 15.2073 21.5595 15.0034 22.0664 14.9999H25.3331V18.8399ZM25.3331 13.5066H22.0664C21.6188 13.5066 21.1756 13.595 20.7622 13.7667C20.3488 13.9384 19.9734 14.19 19.6575 14.5071C19.3416 14.8243 19.0915 15.2006 18.9214 15.6147C18.7513 16.0287 18.6647 16.4723 18.6664 16.9199C18.6664 17.8216 19.0246 18.6864 19.6623 19.3241C20.2999 19.9617 21.1647 20.3199 22.0664 20.3199H25.3331V20.5999C25.3296 21.7764 24.8607 22.9037 24.0288 23.7356C23.1969 24.5675 22.0696 25.0364 20.8931 25.0399H12.3464C11.1676 25.0399 10.0369 24.5726 9.20215 23.7403C8.36737 22.908 7.89662 21.7787 7.89309 20.5999V13.5066C7.8966 12.3266 8.36692 11.1959 9.20132 10.3615C10.0357 9.52708 11.1664 9.05676 12.3464 9.05324H20.8931C22.0719 9.05677 23.2012 9.52752 24.0335 10.3623C24.8657 11.1971 25.3331 12.3278 25.3331 13.5066Z"
          fill={color}
        />
        <path
          d="M21.4805 16.8534C21.4804 17.0492 21.5573 17.2371 21.6944 17.3768C21.8316 17.5164 22.0181 17.5966 22.2138 17.6001H22.5205C22.7185 17.6001 22.9084 17.5215 23.0484 17.3814C23.1885 17.2414 23.2671 17.0515 23.2671 16.8534C23.2636 16.6577 23.1834 16.4712 23.0438 16.3341C22.9041 16.1969 22.7162 16.1201 22.5205 16.1201H22.2138C22.0193 16.1201 21.8328 16.1974 21.6953 16.3349C21.5577 16.4724 21.4805 16.659 21.4805 16.8534Z"
          fill="#FF9723"
        />
        <path
          d="M16.9736 12H11.6402C11.4422 12 11.2523 12.0787 11.1123 12.2187C10.9722 12.3587 10.8936 12.5486 10.8936 12.7467C10.8936 12.9447 10.9722 13.1346 11.1123 13.2746C11.2523 13.4147 11.4422 13.4933 11.6402 13.4933H16.9736C17.1716 13.4933 17.3615 13.4147 17.5015 13.2746C17.6416 13.1346 17.7202 12.9447 17.7202 12.7467C17.7202 12.5486 17.6416 12.3587 17.5015 12.2187C17.3615 12.0787 17.1716 12 16.9736 12Z"
          fill="#FF9723"
        />
      </g>
      <defs>
        <clipPath id="clip0_6511_44899">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const iconQris = (color?: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M12.8691 7.28153C12.9732 7.28953 13.0756 7.31181 13.1736 7.34773C13.2678 7.39414 13.3529 7.4569 13.4252 7.53308C13.5013 7.6024 13.5603 7.68853 13.5973 7.78463C13.6163 7.88524 13.6163 7.98852 13.5973 8.08914V13.1334C13.6173 13.2339 13.6173 13.3374 13.5973 13.4379C13.5603 13.534 13.5013 13.6201 13.4252 13.6895C13.3566 13.7697 13.2706 13.8331 13.1736 13.8748C13.0731 13.8948 12.9696 13.8948 12.8691 13.8748H7.99695C7.89272 13.8764 7.78933 13.856 7.69351 13.815C7.5977 13.7739 7.51162 13.7131 7.4409 13.6365C7.36859 13.5632 7.31147 13.4764 7.27285 13.3809C7.23422 13.2855 7.21485 13.1834 7.21583 13.0804V8.08914C7.19683 7.98852 7.19683 7.88524 7.21583 7.78463C7.25752 7.69106 7.31583 7.60584 7.38794 7.53308C7.46016 7.4569 7.54533 7.39414 7.63949 7.34773C7.73748 7.31181 7.83994 7.28953 7.944 7.28153H12.8691ZM12.8691 5.65306H7.99695C7.6824 5.65898 7.37205 5.72645 7.08342 5.85165C6.79052 5.97466 6.52499 6.15468 6.30229 6.38124C6.08513 6.61126 5.91431 6.88096 5.79918 7.17561C5.6824 7.46587 5.62392 7.77628 5.62707 8.08914V13.1334C5.6214 13.7714 5.86899 14.3856 6.31554 14.8413C6.53344 15.0682 6.79434 15.2495 7.08302 15.3745C7.3717 15.4995 7.68239 15.5658 7.99695 15.5695H12.8956C13.2202 15.5613 13.5398 15.4879 13.8355 15.3537C14.1311 15.2195 14.3968 15.0272 14.6167 14.7884C14.8361 14.5621 15.0086 14.2947 15.1244 14.0017C15.2403 13.7086 15.2972 13.3955 15.292 13.0804V8.08914C15.3002 7.77156 15.2416 7.4558 15.1198 7.16237C15.0112 6.86836 14.8395 6.60173 14.6167 6.38124C14.3964 6.1579 14.1359 5.97821 13.8488 5.85165C13.5414 5.71242 13.2065 5.64452 12.8691 5.65306Z"
        fill={color}
      />
      <path
        d="M10.5391 10.4593V10.8167H10.3008V10.499L10.5391 10.4593ZM11.4394 9.13531H9.41373C9.29876 9.13765 9.18934 9.18522 9.10922 9.26771C9.06577 9.30532 9.03132 9.3522 9.0084 9.40491C8.98549 9.45761 8.9747 9.51479 8.97683 9.57222V11.6773C8.9747 11.7347 8.98549 11.7919 9.0084 11.8446C9.03132 11.8973 9.06577 11.9442 9.10922 11.9818C9.18934 12.0643 9.29876 12.1119 9.41373 12.1142H11.4394C11.554 12.1105 11.663 12.0631 11.7439 11.9818C11.8221 11.8988 11.869 11.7911 11.8763 11.6773V9.55897C11.869 9.44515 11.8221 9.33746 11.7439 9.25446C11.663 9.17314 11.554 9.12579 11.4394 9.12207V9.13531Z"
        fill={color}
      />
      <path
        d="M24.3075 7.28179C24.4071 7.29112 24.505 7.31337 24.5988 7.34799C24.697 7.39425 24.7865 7.45693 24.8636 7.53334C24.9314 7.60681 24.9853 7.69206 25.0225 7.78489C25.0414 7.8855 25.0414 7.98878 25.0225 8.0894V13.1337C25.0424 13.2342 25.0424 13.3377 25.0225 13.4382C24.9854 13.5343 24.9265 13.6204 24.8503 13.6897C24.7845 13.7728 24.6976 13.8368 24.5988 13.8751C24.5026 13.894 24.4037 13.894 24.3075 13.8751H19.3956C19.2949 13.8714 19.196 13.8471 19.1049 13.8039C19.0139 13.7606 18.9326 13.6993 18.8661 13.6235C18.7314 13.4746 18.6561 13.2815 18.6542 13.0807V8.0894C18.6352 7.98878 18.6352 7.8855 18.6542 7.78489C18.6913 7.68879 18.7502 7.60266 18.8263 7.53334C18.8986 7.45716 18.9837 7.3944 19.0779 7.34799C19.1759 7.31207 19.2783 7.2898 19.3824 7.28179H24.2546H24.3075ZM24.3075 5.65332H19.3692C19.0546 5.65925 18.7443 5.72671 18.4556 5.85192C18.1649 5.97183 17.9031 6.15237 17.6877 6.3815C17.4621 6.60741 17.2863 6.87799 17.1714 7.17587C17.0588 7.46695 17.0048 7.7774 17.0125 8.0894V13.1337C17.0043 13.4491 17.0598 13.763 17.1759 14.0565C17.2919 14.35 17.466 14.617 17.6877 14.8416C17.9056 15.0685 18.1666 15.2497 18.4552 15.3747C18.7439 15.4998 19.0546 15.566 19.3692 15.5697H24.281C24.5956 15.566 24.9063 15.4998 25.195 15.3747C25.4836 15.2497 25.7446 15.0685 25.9625 14.8416C26.409 14.3859 26.6566 13.7717 26.6509 13.1337V8.0894C26.6528 7.77236 26.5944 7.45786 26.4788 7.16263C26.3604 6.87051 26.1848 6.60495 25.9625 6.3815C25.7471 6.15237 25.4853 5.97183 25.1946 5.85192C24.9076 5.72175 24.5962 5.65405 24.281 5.65332H24.3075Z"
        fill={color}
      />
      <path
        d="M21.9778 10.4593V10.8167H21.7395V10.499L21.9778 10.4593ZM22.8649 9.13531H20.8392C20.7243 9.13765 20.6149 9.18522 20.5347 9.26771C20.4523 9.34783 20.4047 9.45724 20.4023 9.57222V11.6773C20.4047 11.7923 20.4523 11.9017 20.5347 11.9818C20.6149 12.0643 20.7243 12.1119 20.8392 12.1142H22.8649C22.9799 12.1119 23.0893 12.0643 23.1694 11.9818C23.2519 11.9017 23.2995 11.7923 23.3018 11.6773V9.55897C23.2995 9.444 23.2519 9.33459 23.1694 9.25446C23.0893 9.17198 22.9799 9.12441 22.8649 9.12207V9.13531Z"
        fill={color}
      />
      <path
        d="M12.8691 18.628C12.974 18.6294 13.0776 18.6519 13.1736 18.6942C13.2697 18.7313 13.3559 18.7902 13.4252 18.8663C13.5013 18.9357 13.5603 19.0218 13.5973 19.1179C13.6173 19.2184 13.6173 19.3219 13.5973 19.4224V24.4667C13.5983 24.5696 13.5789 24.6717 13.5403 24.7672C13.5016 24.8626 13.4445 24.9495 13.3722 25.0227C13.2255 25.1724 13.0257 25.2581 12.8162 25.261H7.99696C7.89644 25.281 7.79296 25.281 7.69244 25.261C7.59635 25.224 7.51022 25.1651 7.4409 25.0889C7.36859 25.0113 7.31034 24.9217 7.26878 24.8241C7.24986 24.728 7.24986 24.629 7.26878 24.5329V19.4224C7.2678 19.3195 7.28718 19.2173 7.32581 19.1219C7.36443 19.0265 7.42155 18.9396 7.49386 18.8663C7.64052 18.7167 7.84039 18.631 8.04992 18.628H12.8691ZM12.8691 16.9863H7.99696C7.36277 16.9996 6.75908 17.261 6.31554 17.7145C5.87144 18.1718 5.62427 18.785 5.62708 19.4224V24.4667C5.6239 24.7838 5.68235 25.0986 5.79919 25.3934C5.91774 25.6824 6.08827 25.9471 6.3023 26.1746C6.525 26.4011 6.79052 26.5812 7.08342 26.7042C7.37004 26.8355 7.68168 26.9033 7.99696 26.9028H12.8956C13.2151 26.9035 13.5312 26.8358 13.8224 26.7042C14.1094 26.5776 14.37 26.3979 14.5903 26.1746C14.813 25.9541 14.9847 25.6875 15.0934 25.3934C15.2163 25.1004 15.275 24.7843 15.2655 24.4667V19.4224C15.2732 18.7866 15.0307 18.1731 14.5903 17.7145C14.1337 17.2563 13.5159 16.9949 12.8691 16.9863Z"
        fill={color}
      />
      <path
        d="M10.5386 21.7923V22.11H10.3003V21.7923H10.5386ZM11.4389 20.4683H9.41322H9.24111C9.18794 20.4912 9.13877 20.5225 9.09548 20.561L9.0028 20.7066C8.99646 20.7638 8.99646 20.8215 9.0028 20.8787V22.9838C9.00159 23.0411 9.0128 23.098 9.03565 23.1506C9.0585 23.2032 9.09246 23.2501 9.1352 23.2883C9.17553 23.3274 9.22321 23.358 9.27549 23.3785C9.32776 23.3989 9.38358 23.4088 9.4397 23.4075H11.4654C11.5785 23.4087 11.6876 23.366 11.7699 23.2883C11.8455 23.2037 11.8919 23.0969 11.9023 22.9838V20.9052C11.898 20.7868 11.851 20.6739 11.7699 20.5875C11.726 20.5458 11.6739 20.5136 11.6169 20.4931C11.5599 20.4726 11.4993 20.4642 11.4389 20.4683Z"
        fill={color}
      />
      <path
        d="M24.3083 18.628C24.4087 18.6312 24.5076 18.6536 24.5996 18.6942C24.6975 18.7277 24.7845 18.7872 24.8511 18.8663C24.9273 18.9357 24.9862 19.0218 25.0232 19.1179C25.0432 19.2184 25.0432 19.3219 25.0232 19.4224V24.4667C25.0242 24.5696 25.0048 24.6717 24.9662 24.7672C24.9276 24.8626 24.8705 24.9495 24.7982 25.0227C24.6556 25.1704 24.4606 25.256 24.2553 25.261H19.3435C19.1438 25.2488 18.9555 25.1641 18.8139 25.0227C18.6813 24.8725 18.6063 24.6802 18.6021 24.4799V19.4356C18.5986 19.3335 18.6155 19.2317 18.6519 19.1363C18.6883 19.0408 18.7434 18.9535 18.8139 18.8796C18.9527 18.7324 19.1417 18.6426 19.3435 18.628H24.3083ZM24.3083 16.9863H19.3964C18.7622 16.9996 18.1585 17.261 17.715 17.7145C17.4945 17.9399 17.3212 18.2071 17.2052 18.5004C17.0893 18.7937 17.033 19.1071 17.0398 19.4224V24.4667C17.0321 24.783 17.086 25.0978 17.1987 25.3934C17.3171 25.6856 17.4926 25.9511 17.715 26.1746C17.9304 26.4037 18.1922 26.5843 18.4829 26.7042C18.7695 26.8355 19.0812 26.9033 19.3964 26.9028H24.3083C24.619 26.9002 24.9257 26.8325 25.2086 26.7042C25.4993 26.5843 25.7611 26.4037 25.9765 26.1746C26.2146 25.9509 26.4039 25.6805 26.5325 25.3802C26.6494 25.0853 26.7078 24.7706 26.7047 24.4534V19.4224C26.7075 18.785 26.4603 18.1718 26.0162 17.7145C25.7934 17.4868 25.5278 17.3053 25.2348 17.1803C24.9417 17.0554 24.6269 16.9895 24.3083 16.9863Z"
        fill={color}
      />
      <path
        d="M21.9775 21.7924V22.1101H21.7392V21.7924H21.9775ZM22.8646 20.4684H20.8389H20.6668L20.5344 20.5611C20.4944 20.6032 20.4629 20.6527 20.4417 20.7067C20.4296 20.7635 20.4296 20.8221 20.4417 20.8789V22.9839C20.4455 23.0986 20.4928 23.2075 20.5741 23.2885C20.6145 23.3275 20.6622 23.3581 20.7144 23.3786C20.7667 23.3991 20.8225 23.4089 20.8786 23.4076H22.9043C22.9604 23.4089 23.0162 23.3991 23.0685 23.3786C23.1208 23.3581 23.1685 23.3275 23.2088 23.2885C23.2901 23.2075 23.3375 23.0986 23.3412 22.9839V20.9053C23.337 20.7869 23.2899 20.674 23.2088 20.5876C23.1639 20.5434 23.1097 20.5097 23.0501 20.4891C22.9906 20.4685 22.9272 20.4614 22.8646 20.4684Z"
        fill={color}
      />
      <path
        d="M3.42878 8.16885C3.21428 8.15897 3.01121 8.06924 2.85947 7.9173C2.70933 7.76018 2.6242 7.55207 2.62117 7.33476V5.85193C2.61575 5.10112 2.90583 4.37829 3.42878 3.83952C3.68658 3.57299 3.99507 3.36072 4.33613 3.2152C4.67719 3.06968 5.04391 2.99386 5.41471 2.99219H6.84458C7.05332 3.00554 7.24838 3.10069 7.38741 3.25698C7.52879 3.40801 7.60901 3.60619 7.61248 3.81304C7.61232 4.02447 7.53187 4.22796 7.38741 4.38234C7.24838 4.53863 7.05332 4.63378 6.84458 4.64713H5.41471C5.25961 4.65576 5.10725 4.69161 4.96457 4.75305C4.81994 4.8082 4.68894 4.89402 4.58062 5.0046C4.47505 5.11696 4.38992 5.2469 4.32906 5.38855C4.26946 5.53547 4.24237 5.69355 4.24963 5.85193V7.33476C4.27103 7.4396 4.27103 7.54768 4.24963 7.65251C4.21312 7.75271 4.15425 7.84326 4.07751 7.9173C4.00154 7.99502 3.91174 8.05788 3.81272 8.10266C3.69222 8.15625 3.56028 8.179 3.42878 8.16885Z"
        fill="#FF9723"
      />
      <path
        d="M24.1486 3.81279C24.1233 3.70838 24.1233 3.59945 24.1486 3.49504C24.1886 3.39662 24.247 3.30675 24.3207 3.23025C24.3972 3.15654 24.4871 3.09812 24.5855 3.05813C24.69 3.03336 24.7988 3.03336 24.9032 3.05813H26.3464C26.7154 3.05984 27.0802 3.13641 27.4188 3.28321C27.7589 3.42567 28.0656 3.63761 28.319 3.90546C28.58 4.17212 28.7869 4.48685 28.9281 4.83223C29.0601 5.17892 29.1274 5.54689 29.1267 5.91788V7.4007C29.1236 7.61801 29.0385 7.82613 28.8883 7.98325C28.7312 8.13339 28.5231 8.21853 28.3058 8.22156C28.0928 8.21652 27.8896 8.13143 27.7365 7.98325C27.5864 7.82613 27.5012 7.61801 27.4982 7.4007V5.85168C27.4997 5.69864 27.4699 5.54691 27.4107 5.40577C27.3515 5.26463 27.2641 5.13705 27.154 5.03083C26.9408 4.81397 26.6504 4.69023 26.3464 4.6866H24.9032C24.7938 4.70646 24.6817 4.70646 24.5722 4.6866C24.476 4.63174 24.3871 4.56502 24.3075 4.48801C24.2305 4.40902 24.1717 4.31411 24.1354 4.20997C24.1006 4.07915 24.1052 3.941 24.1486 3.81279Z"
        fill="#FF9723"
      />
      <path
        d="M28.3588 24.3477C28.5776 24.3556 28.7856 24.4453 28.9414 24.5992C29.0893 24.7577 29.1741 24.965 29.1797 25.1817V26.7043C29.1851 27.4551 28.895 28.1779 28.3721 28.7167C28.1165 28.9835 27.8105 29.1969 27.4719 29.3446C27.1333 29.4923 26.7687 29.5714 26.3994 29.5773H24.9562C24.7322 29.5755 24.518 29.4851 24.3605 29.3257C24.2844 29.2491 24.2242 29.1582 24.1833 29.0582C24.1424 28.9582 24.1216 28.8512 24.1222 28.7432C24.1206 28.6331 24.1408 28.5237 24.1817 28.4215C24.2226 28.3192 24.2834 28.2261 24.3605 28.1474C24.518 27.9881 24.7322 27.8976 24.9562 27.8959H26.3994C26.5532 27.8948 26.7053 27.8626 26.8464 27.8012C26.9875 27.7398 27.1146 27.6503 27.2202 27.5384C27.3296 27.4295 27.4155 27.2993 27.4724 27.1558C27.5294 27.0123 27.5562 26.8586 27.5512 26.7043V25.1817C27.5506 25.0737 27.5714 24.9667 27.6123 24.8667C27.6532 24.7668 27.7134 24.6759 27.7895 24.5992C27.9397 24.445 28.1437 24.3549 28.3588 24.3477Z"
        fill="#FF9723"
      />
      <path
        d="M7.66537 28.7034C7.66592 28.8114 7.64516 28.9185 7.60427 29.0184C7.56337 29.1184 7.50315 29.2093 7.42705 29.2859C7.35197 29.365 7.26164 29.4281 7.16152 29.4713C7.0614 29.5146 6.95356 29.5371 6.84451 29.5375H5.41465C5.0405 29.5312 4.67137 29.4502 4.32899 29.2992C3.99114 29.1525 3.6853 28.9411 3.42871 28.6769C3.16771 28.4103 2.96088 28.0955 2.81969 27.7502C2.68762 27.4035 2.62032 27.0355 2.6211 26.6645V25.1817C2.62249 25.0768 2.64501 24.9732 2.6873 24.8772C2.73209 24.7813 2.79001 24.6922 2.8594 24.6124C2.93924 24.543 3.02838 24.4851 3.12421 24.4403C3.22473 24.4203 3.32819 24.4203 3.42871 24.4403C3.53382 24.4217 3.64135 24.4217 3.74645 24.4403C3.84228 24.4851 3.93142 24.543 4.01126 24.6124C4.08065 24.6922 4.13857 24.7813 4.18336 24.8772C4.20333 24.9777 4.20333 25.0812 4.18336 25.1817V26.7042C4.1761 26.8626 4.20319 27.0207 4.26279 27.1676C4.32365 27.3093 4.40878 27.4392 4.51435 27.5516C4.62267 27.6621 4.75367 27.748 4.89831 27.8031C5.04098 27.8646 5.19334 27.9004 5.34844 27.909H6.77832C6.99562 27.9121 7.20373 27.9972 7.36085 28.1473C7.53159 28.2854 7.64101 28.4852 7.66537 28.7034Z"
        fill="#FF9723"
      />
    </svg>
  );
};
