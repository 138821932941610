import React, { FC, Component, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../core/feature/hooks";
import Company from "../core/models/company";
import LogoClosepay from "../assets/images/closepayLogo.svg";

const Footer = () => {
  const company: Company = useAppSelector((state) => state.company.company);
  let logo;
  if (company) {
    if (company?.logo == "string" || null || undefined) {
      logo = LogoClosepay;
    } else {
      logo = company.logo;
    }
  }
  return (
    <div className="absolute-bottom px-4 py-4 flex flex-col justify-center items-center">
      <div className="text-[#26A69A] text-lg mb-2">Powered by</div>
      <img src={logo} className="w-20" />
    </div>
  );
};

export default Footer;
