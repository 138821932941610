import Config from "../../config/Config";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
import { cekToken } from "./api";
const axios = require("axios");

const baseUrlDev = Config.BaseUrlDev;

export const listProduct = (query) => {
  let url = `${baseUrlDev}commerce/merchant/product?companyId=string&email=string&name=string&userId=string&userType=user_admin&username=string&phone=string&noId=string&role=string&logo=string&page=${query.page}&size=${query.size}&sortBy=${query.sort}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const searchProduct = (query) => {
  let url = `${baseUrlDev}commerce/merchant/product?companyId=string&email=string&name=string&userId=string&userType=user_admin&username=string&phone=string&noId=string&role=string&logo=string&productName=${query.name}&page=${query.page}&size=${query.size}&sortBy=${query.sort}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const Product = (id) => {
  let url = `${baseUrlDev}commerce/merchant/product/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getCategories = (query) => {
  let url = `${baseUrlDev}commerce/category?companyId=string&email=string&name=string&userId=string&userType=user_admin&username=string&phone=string&noId=string&role=string&logo=string&page=1&size=10&sortBy=-createdDate`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const findSortCategory = (query) => {
  let url = `${baseUrlDev}commerce/merchant/product?categoryId=${query.categoryId}&companyId=string&email=string&name=string&userId=string&userType=user_admin&username=string&phone=string&noId=string&role=string&logo=string&archived=${query.archive}&displayed=${query.display}&page=${query.page}&size=${query.size}&sortBy=${query.combobox}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const listOrder = (query) => {
  let url = `${baseUrlDev}commerce/merchant/order?companyId=string&email=string&name=string&userId=string&userType=user_admin&username=string&phone=string&noId=string&role=string&logo=string&page=${query.page}&size=${query.size}&sortBy=${query.sort}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const findSortStatus = (query) => {
  let url = `${baseUrlDev}commerce/merchant/order?companyId=string&email=string&name=string&userId=string&userType=user_admin&username=string&phone=string&noId=string&role=string&logo=string&page=1&size=10&sortBy=${query.sort}&status=WAITING_PAYMENT`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getOrder = (id) => {
  let url = `${baseUrlDev}commerce/merchant/order/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateStatusOrder = (orderId, data) => {
  let url = `${baseUrlDev}commerce/merchant/order/${orderId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateSent = (orderId, data) => {
  let url = `${baseUrlDev}commerce/merchant/order/${orderId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const listShip = (query) => {
  let url = `${baseUrlDev}commerce/merchant/ship?page=${query.page}&size=${query.size}&sortBy=${query.sort}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getShip = (id) => {
  let url = `${baseUrlDev}commerce/merchant/ship/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const deleteShip = (orderId, data) => {
  let url = `${baseUrlDev}commerce/merchant/ship/${orderId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const addShip = (data) => {
  let url = `${baseUrlDev}commerce/merchant/ship`;
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateShip = (orderId, data) => {
  let url = `${baseUrlDev}commerce/merchant/ship/${orderId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const lisTransaction = (query) => {
  let url = `${baseUrlDev}commerce/merchant/order/transaction?companyId=string&email=string&name=string&userId=string&userType=user_admin&username=string&phone=string&noId=string&role=string&logo=string&page=${query.page}&size=${query.size}&sortBy=${query.sort}&monthFilter=${query.monthYear}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const downloadExcel = (query) => {
  let url = `${baseUrlDev}commerce/merchant/order/transaction/excel?monthFilter=${query}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadPdf = (id) => {
  let url = `${baseUrlDev}commerce/merchant/order/transaction/pdf/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage
            .getItem("token_redux")
            .replaceAll('"', "")}`,
          "Content-Type": "application/pdf",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
